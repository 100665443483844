<template>
  <ValidationObserver>
    <div class="contact-form" data-testid="contact-form" @submit.prevent="passes(validateForm(mutableValue))">
        <!-- Contact Name form input -->
        <ValidationProvider ref="nameProvider" rules="required" name="Contact Name" v-slot="{ errors }" slim>
          <div class="form-group" :class="{ 'has-error': errors[0] }">
            <label :for="fieldPrefix('contact_name')" class="col-sm-2 control-label" v-if="!deleteLabel">Contact Name</label>
            <div :class="inputWidth">
              <div class="input-group">
                <span class="input-group-addon"><i class="icon icon-user"></i></span>
                <input data-testid="name" class="form-control" :id="fieldPrefix('contact_name')" :name="fieldPrefix('contact_name')" placeholder="Contact Name" type="text" v-model="mutableValue.name">
              </div>
              <span v-show="errors" class="text-danger">{{ errors[0] }}</span>
            </div>
          </div>
        </ValidationProvider>
        <!-- Job Role form input -->
        <ValidationProvider ref="roleProvider" :rules="{required: false}" name="Job Role" v-slot="{ errors }" slim>
          <div class="form-group" :class="{ 'has-error': errors[0] }">
            <label :for="fieldPrefix('contact_name')" class="col-sm-2 control-label" v-if="!deleteLabel">Job Role</label>
            <div :class="inputWidth">
              <div class="input-group">
                <span class="input-group-addon"><i class="icon icon-user"></i></span>
                <input data-testid="job-role" class="form-control" :id="fieldPrefix('job_role')" :name="fieldPrefix('job_role')" placeholder="Job Role" type="text" v-model="mutableValue.job_role">
              </div>
              <span v-show="errors" class="text-danger">{{ errors[0] }}</span>
            </div>
          </div>
        </ValidationProvider>
        <!-- Address Line 1 form input -->
        <ValidationProvider ref="addressProvider" rules="required" name="Address Line 1" v-slot="{ errors }" slim>
          <div class="form-group" :class="{ 'has-error': errors[0] }">
            <label :for="fieldPrefix('address_line_1')" class="col-sm-2 control-label" v-if="!deleteLabel">Address Line 1</label>
            <div :class="inputWidth">
              <div class="input-group">
                <span class="input-group-addon"><i class="icon icon-house"></i></span>
                <input data-testid="address-line-1" class="form-control" :id="fieldPrefix('address_line_1')" :name="fieldPrefix('address_line_1')" placeholder="Address Line 1" type="text" v-model="mutableValue.address.line1">
              </div>
              <span v-show="errors" class="text-danger">{{ errors[0] }}</span>
            </div>
          </div>
        </ValidationProvider>
        <!-- Address Line 2 form input -->
        <div class="form-group">
          <label :for="fieldPrefix('address_line_2')" class="col-sm-2 control-label" v-if="!deleteLabel">Address Line 2</label>
          <div :class="inputWidth">
            <div class="input-group">
              <span class="input-group-addon"><i class="icon icon-house"></i></span>
              <input data-testid="address-line-2" class="form-control" :id="fieldPrefix('address_line_2')" :name="fieldPrefix('address_line_2')" placeholder="Address Line 2" type="text" v-model="mutableValue.address.line2">
            </div>
          </div>
        </div>
        <!-- Postcode form input -->
        <ValidationProvider ref="postcodeProvider" rules="required" name="Postcode" v-slot="{ errors }" slim>
          <div class="form-group" :class="{ 'has-error': errors[0] }">
            <label :for="fieldPrefix('postcode')" class="col-sm-2 control-label" v-if="!deleteLabel">Postcode</label>
            <div :class="inputWidth">
              <div class="input-group">
                <span class="input-group-addon"><i class="icon icon-house"></i></span>
                <input data-testid="postcode" class="form-control" :id="fieldPrefix('postcode')" :name="fieldPrefix('postcode')" placeholder="Postcode" type="text" v-model="mutableValue.postcode">
              </div>
              <span v-show="errors" class="text-danger">{{ errors[0] }}</span>
            </div>
          </div>
        </ValidationProvider>
        <!-- Country Code form select -->
        <ValidationProvider ref="countryProvider" :rules="{ required: true }" name="Country" v-slot="{ errors }" slim>
          <div class="form-group" :class="{ 'has-error': errors[0] }">
            <label :for="fieldPrefix('country')" class="col-sm-2 control-label" v-if="!deleteLabel">Country</label>
            <div :class="inputWidth">
              <div class="input-group">
                <select data-testid="country-code" class="form-control" name="country" :id="fieldPrefix('country')"
                        v-model="mutableValue.countryCode">
                  <option v-for="country in countries" :value="country['id']" :selected="country['id'] === value" :key="country['id']">{{country['name']}}</option>
                </select>
              </div>
              <span v-show="errors" class="text-danger">{{ errors[0] }}</span>
            </div>
          </div>
        </ValidationProvider>
        <!-- Region Code form select -->
        <!-- <ValidationProvider ref="regionProvider" name="region" v-slot="{ errors }" slim> -->
          <!--
            !!!!! DEVELOPER WARNING !!!!

            Enabling this validator WILL BREAK Google Chrome. The select box will start setting the value to undefined, with no way to select an option.

            ValidationProvider can't wrap 2 fields, and splitting this out to 2 providers seems to not work either, possible because of the v-if.
            It definitely is solveable, but i'm not sure how and we don't currently need any validation on this field anyway, so i've commented it out.

            If you need validation, make sure you test for this bug and fix it.
          -->
          <!-- <div class="form-group" :class="{ 'has-error': errors[0] }"> -->
            <div class="form-group">
            <label :for="fieldPrefix('region')" class="col-sm-2 control-label" v-if="!deleteLabel">Region</label>
            <div :class="inputWidth">
              <div class="input-group">
                <select v-if="regions.length > 0" data-testid="region" class="form-control" name="region" :id="fieldPrefix('region')"
                        v-model="mutableValue.region">
                  <optgroup :label="group['group_name'] != '' ? group['group_name'] : 'Choose a region'" v-for="(group, key) in regions" :key="key">
                    <option v-for="(region, k) in group['values']" :value="region" :selected="region === value" :key="k">{{region}}</option>
                  </optgroup>
                </select>
                <input v-else data-testid="region" class="form-control" :name="fieldPrefix('region')" :id="fieldPrefix('region')" placeholder="Region" type="text" v-model="mutableValue.region">
              </div>
              <!-- <span v-show="errors" class="text-danger">{{ errors[0] }}</span> -->
            </div>
          </div>
        <!-- </ValidationProvider> -->

        <!-- Phone form input -->
        <ValidationProvider ref="phoneProvider" :rules="{ required: true, regex: /^[+0-9]+$/ }" name="Phone" v-slot="{ errors }" slim>
          <div class="form-group" :class="{ 'has-error': errors[0] }">
            <label :for="fieldPrefix('phone')" class="col-sm-2 control-label" v-if="!deleteLabel">Phone</label>
            <div :class="inputWidth">
              <div class="input-group">
                <span class="input-group-addon"><i class="icon icon-phone"></i></span>
                <input data-testid="phone" class="form-control" :name="fieldPrefix('phone')" :id="fieldPrefix('phone')" placeholder="Phone" type="text" v-model="mutableValue.phone">
              </div>
              <span v-show="errors" class="text-danger">{{ errors[0] }}</span>
            </div>
          </div>
        </ValidationProvider>
        <!-- City form input -->
        <ValidationProvider ref="cityProvider" name="city" v-slot="{ errors }" slim>
          <div class="form-group" :class="{ 'has-error': errors[0] }">
            <label :for="fieldPrefix('city')" class="col-sm-2 control-label" v-if="!deleteLabel">City</label>
            <div :class="inputWidth">
              <div class="input-group">
                <span class="input-group-addon"><i class="icon icon-city"></i></span>
                <input data-testid="city" class="form-control" :name="fieldPrefix('city')" :id="fieldPrefix('city')" placeholder="City" type="text" v-model="mutableValue.city">
              </div>
              <span v-show="errors" class="text-danger">{{ errors[0] }}</span>
            </div>
          </div>
        </ValidationProvider>
        <!-- county form input -->
        <ValidationProvider ref="countyProvider" name="county" v-slot="{ errors }" slim>
          <div class="form-group" :class="{ 'has-error': errors[0] }">
            <label :for="fieldPrefix('county')" class="col-sm-2 control-label" v-if="!deleteLabel">County</label>
            <div :class="inputWidth">
              <div class="input-group">
                <span class="input-group-addon"><i class="icon icon-county"></i></span>
                <input data-testid="county" class="form-control" :name="fieldPrefix('county')" :id="fieldPrefix('county')" placeholder="County" type="text" v-model="mutableValue.county">
              </div>
              <span v-show="errors" class="text-danger">{{ errors[0] }}</span>
            </div>
          </div>
        </ValidationProvider>
        <!-- Email form input -->
        <ValidationProvider ref="emailProvider" rules="required|email" name="Email" v-slot="{ errors }" slim>
          <div class="form-group" :class="{ 'has-error': errors[0] }">
            <label :for="fieldPrefix('email')" class="col-sm-2 control-label" v-if="!deleteLabel">Email</label>
            <div :class="inputWidth">
              <div class="input-group">
                <span class="input-group-addon"><i class="icon icon-envelope"></i></span>
                <input data-testid="email" class="form-control" :id="fieldPrefix('email')" :name="fieldPrefix('email')" placeholder="Email"
                      type="email" v-model="mutableValue.email">
              </div>
              <span v-show="errors" class="text-danger">{{ errors[0] }}</span>
            </div>
          </div>
        </ValidationProvider>
    </div>
  </ValidationObserver>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'contact-form',
  props: ['value', 'prefix', 'deleteLabel', 'inputWidth'],
  data() {
    return {
      countries: [],
      regions: [],
      mutableValue: Object.assign(this.value),
    };
  },
  mounted() {
    this.getCountries();
  },
  methods: {
    fieldPrefix(value) {
      if (_.isEmpty(this.prefix)) {
        return value;
      }

      return `${this.prefix}_${value}`;
    },
    updateRegions(countryCode) {
      const selectedCountry = this.countries.find((country) => country.id === countryCode);
      this.regions = selectedCountry && selectedCountry.regions ? selectedCountry.regions.groups : [];
    },
    getCountries() {
      if (this.$store.state.countries !== null) {
        this.countries = this.$store.state.countries;
        this.updateRegions(this.mutableValue.countryCode);
      } else {
        this.$http.get('app/config/countries')
          .then((response) => {
            this.countries = response.data.countries;
            this.$store.dispatch('fillCountries', this.countries);
            this.updateRegions(this.mutableValue.countryCode);
          })
          .catch((error) => {
            this.$apiResponse.renderErrorMessage(error);
          });
      }
    },
  },
  watch: {
    'mutableValue.countryCode': function (newCountryCode) {
      this.mutableValue.region = null;
      this.updateRegions(newCountryCode);
    },
  },
};
</script>
