<template>
  <!-- Main content -->
  <div class="container container-table">
    <div class="row">
      <div class="col-md-3 col-sm-1"></div>
      <div class="col-md-6 col-sm-10">
        <div class="row">
          <div class="col-centered col-md-10">
            <div class="forgotten-form box-front">
              <img src="../assets/img/fonetti-logo-new.png" class="center-block logo">
              <action-messages></action-messages>
              <forgotten-password-form v-model="form" :is-loading="loading"
                                       @validated="resetPassword"></forgotten-password-form>
              <div class="extra-links extra-links-center">
                <router-link to="/login">Login to your Account</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-sm-1"></div>
    </div>
  </div>
  <!-- /.content -->
</template>

<script>
import ForgottenPasswordForm from '@/components/forms/ForgottenPasswordForm';

export default {
  name: 'Forgotten',
  data() {
    return {
      section: 'Forgotten password',
      loading: false,
      form: {
        email: this.$store.state.emailAddress,
        isSchool: true,
      },
    };
  },
  mounted() {
    this.$apiResponse.showHeading(false);
  },
  methods: {
    resetPassword() {
      this.loading = true;
      this.$http.post('users/resetpassword', {
        email: this.form.email,
        isSchool: true,
      }).then((response) => {
        this.$apiResponse.renderSuccessMessage(response, 'An email with your new password has been sent to your address.');
      }).catch((error) => {
        this.$apiResponse.renderErrorMessage(error, error.response.data.error === 'User not found' ? 'Sorry, that email is not registered with us.' : null);
      }).finally(() => {
        this.loading = false;
      });
    },
  },
  components: {
    ForgottenPasswordForm,
  },
};
</script>
<style>
.container-table {
    display: table;
    color: white;
}
</style>

<style scoped>
  .action-messages {
    margin-bottom: 15px;
  }
</style>
