<template>
  <div class="container container-table">
      <div class="row">
        <div class="col-md-3 col-sm-1"></div>
        <div class="col-md-6 col-sm-10">
          <div class="row">
            <div class="col-centered col-md-10">
              <div class="login-form box-front">
                <img src="../assets/img/fonetti-logo-new.png" class="center-block logo">
                <action-messages></action-messages>
                <div class="alert alert-warning" role="alert" v-show="message">
                  <h4>
                    <i class="icon icon-info"></i> Information
                  </h4>
                  <div v-html="message"></div>
                </div>
                <login-form v-model="form" @validated="login(form)" :is-loading="isLoading"></login-form>
                <div class="extra-links extra-links-center">
                    <router-link to="/forgotten">Forgotten Password</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-sm-1"></div>
      </div>

    <modal title="Confirm" style="color: #555" v-if="modal.isVisible('confirm-accept-invite')" @close="closeConfirmModal">
      <h3 class="text-center">Accepting the invite will delete your readers.<br>Do you still wish to accept?</h3>
      <div class="text-center">
        <loading-button :is-loading="isLoading" @click="runInviteProcess" class="btn btn-lg btn-yes btn-primary">Yes</loading-button>
        <button @click="declineInvite" class="btn btn-no btn-lg btn-primary">No</button>
      </div>
    </modal>
  </div>
</template>

<script>
import Bugsnag from '@bugsnag/js';
import SubscriptionMixin from '@/components/mixins/SubscriptionMixin';
import CurrentUserMixin from '@/components/mixins/CurrentUserMixins';
import InviteMixin from '@/components/mixins/InviteMixin';
import LoginForm from '@/components/forms/LoginForm';
import Form from '@/components/classes/Form';
import ModalJS from '@/components/utils/Modal';
import Modal from '@/components/classes/Modal';
import LoadingButton from '@/components/utils/LoadingButton';

export default {
  name: 'Login',
  mixins: [SubscriptionMixin, InviteMixin, CurrentUserMixin],
  components: {
    LoadingButton,
    LoginForm,
    modal: ModalJS,
  },
  data() {
    return {
      section: 'Login',
      isLoading: false,
      form: new Form({
        email: this.$store.state.emailAddress,
        password: '',
      }),
      invite: null,
      message: null,
      modal: new Modal({
        confirmAcceptInvite: false,
      }),
    };
  },
  mounted() {
    this.invite = window.localStorage.getItem('invite');
    if (this.$route.query.action === 'decline') {
      this.message = 'You have declined the invitation';
    }
    this.$apiResponse.showHeading(false);
  },
  methods: {
    async login(data) {
      this.isLoading = true;
      this.$store.dispatch('login', data)
        .then(() => {
          this.getMe()
            .then((user) => {
              if (this.invite) {
                // first, need to check if readers
                if (user.user_type === 'standard' && user.readers.length > 0) {
                  // show modal
                  this.isLoading = false;
                  this.modal.show('confirm-accept-invite');
                } else {
                  this.runInviteProcess();
                }
              } else {
                this.handleSubscriptionProcess()
                  .then((route) => {
                    this.$router.push(route);
                  });
              }
            });
        })
        .catch((err) => {
          if (err?.response == null) {
            Bugsnag.notify(new Error('Login failed'), (event) => {
              event.severity = 'error';
              event.context = 'Login';
              const cloneErr = JSON.parse(JSON.stringify(err));
              cloneErr.config.data = [];
              event.addMetadata('params', { response: JSON.stringify(cloneErr) });
            });
          }

          const errMessage = err?.response?.data?.error;
          this.$apiResponse.renderErrorMessage(err, errMessage ?? 'Oops something went wrong, please try again.');
          this.isLoading = false;
        });
    },
    declineInvite() {
      this.decline(this.invite);
      this.modal.hide('confirm-accept-invite');
      this.message = 'You have declined the invitation. <a href="https://www.fonetti.com/get-in-touch/">Contact us for more information</a>';
      this.isLoading = false;
    },
    runInviteProcess() {
      this.handleInviteProcess(this.invite)
        .then((route) => {
          this.$router.push(route);
        });
    },
    closeConfirmModal() {
      this.modal.hide('confirm-accept-invite');
      this.isLoading = false;
      this.$store.dispatch('logout');
    },
  },
};
</script>

<style>
.container-table {
    display: table;
    color: white;
}
</style>

<style scoped>
.action-messages {
  margin-bottom: 15px;
}
</style>
