<template>
  <div class="container">
        <div class="row">
          <div class="col-centered col-md-10">
            <div class="setup-form box-front">
              <img src="../assets/img/fonetti-logo-new.png" class="center-block logo">
              <action-messages></action-messages>
                <div class="box-header">
                  <h3 class="box-title">Create new password</h3>
                  <p>First you need to create a new password which you will use to login to the portal.</p>
                </div>
                <div class="box-body">
                  <reset-password-form v-model="form" @validated="resetPassword" :is-loading="isLoading"></reset-password-form>
                </div>
              </div>
          </div>
        </div>
  </div>
</template>

<script>
import CurrentUserMixin from '@/components/mixins/CurrentUserMixins';
import ResetPasswordForm from '@/components/forms/ResetPasswordForm';
import SchoolMixins from '@/components/mixins/SchoolMixins';
// import config from '@/config';

export default {
  name: 'setup',
  mixins: [CurrentUserMixin, SchoolMixins],
  components: { ResetPasswordForm },
  data() {
    return {
      form: {
        password: '',
        email: Object.assign(this.$store.state.user.email),
      },
      isLoading: false,
    };
  },
  methods: {
    resetPassword() {
      this.isLoading = true;
      this.$http.post('users/me', { password: this.form.password })
        .then(() => {
          this.recycleAuthToken(this.form)
            .then(() => {
              this.$router.push('/').catch(() => {});
            }).catch((error) => {
              this.isLoading = false;
              this.$apiResponse.renderErrorMessage(error);
            });
        });
    },
    recycleAuthToken(credentials) {
      // Stole most this from App.vue and Login.vue, can refactor this more cleanly later
      return new Promise((resolve, reject) => {
        try {
          this.$http.delete('auth').then(() => {
            this.$store.dispatch('destroyUser')
              .then(() => {
                this.$store.dispatch('login', credentials)
                  .then((user) => {
                    this.getMe()
                      .then(() => {
                        resolve(user);
                      });
                  });
              });
          });
        } catch (err) {
          reject(err);
        }
      });
    },
  },
};
</script>
