<template>
  <div class="small-buttons-locator">
    <div>
      <a @click="$emit('show-book-details', book)" class="btn btn-primary"><span>Book info</span></a>
      <a @click="$emit('open-preview', book)" class="btn btn-primary btn-primary-yellow" v-if="book.book_preview_url"><span>Preview book</span></a>
    </div>
    <div>
      <a @click="$emit('open-questions', book)" class="btn btn-primary btn-quiz" v-if="book.questions_sheet_url"><span>Quiz Sheet</span></a>
      <a @click="$emit('open-answers', book)" class="btn btn-primary btn-answers" v-if="book.answers_sheet_url"><span>Answers Sheet</span></a>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    book: {
      required: true,
    },
  },
  name: 'book-buttons',
};
</script>

<style scoped>
  .shuffle .btn-primary {
    padding: 6px 12px;
    text-transform: none;
    font-size: 14px;
    font-weight: normal;
    margin-top: 5px;
  }
  .shuffle .btn-primary .icon::before {
    vertical-align: -1px;
  }
  .list-container:not(:first-child) {
    margin-top:2em;
  }
  .list-title {
    font-weight:300;
    margin: 0 4px 0.2em 4px;
  }
  .small-buttons-locator {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
  }
  .small-buttons-locator > div {
    display: flex;
    width: 100%;
    align-items: center;

  }
  .small-buttons-locator > div > a.btn {
    flex: 1;
    font-weight: 600;
  }
  .small-buttons-locator > div > a.btn-quiz, .small-buttons-locator > div > a.btn-answers {
    background: #bc4083;
  }
</style>
