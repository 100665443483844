<template>
  <div class="container container-table">
    <div class="row">
      <div class="col-md-10">
        <div class="setup-form box-front" >
          <div class="box-header box-header-with-boarder">
            <div class="action-messages">
              <router-link to="/login"><img src="../assets/img/fonetti-logo-new.png" class="center-block logo"></router-link>

              <div v-if="status === 'pending'" class="setup-token-loading">
                <i class="fa fa-cog fa-spin"></i>
              </div>

              <div role="alert" class="alert alert-danger" v-if="status === 'error'">
                <h4><i class="icon icon-ban"></i> Error </h4>
                <div v-html="errorMessage"></div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CurrentUserMixin from '@/components/mixins/CurrentUserMixins';

export default {
  name: 'setup',
  mixins: [CurrentUserMixin],
  data() {
    return {
      status: 'pending',
      errorMessage: '',
    };
  },
  mounted() {
    this.validateToken();
  },
  methods: {
    validateToken() {
      this.$store.dispatch('setToken', this.$route.params.token).then(() => {
        this.getMe()
          .then(() => {
            this.$router.push('/').catch(() => {});
          })
          .catch((e) => {
            this.status = 'error';

            if (!e.response || !e.response.status) {
              this.errorMessage = '<p>We were unable to communicate with our online services. This could be due to your schools firewall or network settings.</p>'
              + '<p>You may need to contact your schools network provider or IT administrator to resolve this, information about the required firewall exceptions can be found '
              + 'in <a href="/files/fonetti-technical-requirements.pdf" target="_blank">this document</a>.</p>'
              + '<p>Please contact <a href="mailto:support@fonetti.com">support@fonetti.com</a> if you require further assistance or have any questions.</p>';
            }

            if (e.response && e.response.status) {
              if (e.response.status === 401) {
                this.errorMessage = '<p>This setup link is no longer valid, please contact us to generate a new link.</p>';
                return;
              }
            }

            // Throw the error for Bugsnag to capture
            throw e;
          });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.setup-token-loading {
  text-align:center;
  i {
    font-size: 3em;
    color: #cfcfcf;
  }
}
</style>
