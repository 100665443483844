import _ from 'lodash';

export default {
  data() {
    return {
    };
  },
  methods: {
    getSchool(schoolId) {
      return new Promise((resolve, reject) => {
        this.$http.get(`enterprises/${schoolId}`)
          .then((response) => {
            this.$store.commit('SET_CURRENT_SCHOOL', response.data.enterprise);
            resolve(response.data.enterprise);
          })
          .catch((error) => {
            this.$apiResponse.renderErrorMessage(error);
            reject(error);
          });
      });
    },
    formatSchool(school) {
      const formattedSchool = {};
      formattedSchool.id = school.id;
      formattedSchool.name = school.name;
      formattedSchool.urn = school.urn;
      formattedSchool.recording_enabled = school.recording_enabled;
      formattedSchool.can_enable_recordings = school.can_enable_recordings;

      // eslint-disable-next-line
      if (_.isEmpty(school.contacts)) {
        formattedSchool.primaryContact = this.formatEmptyContact();
        formattedSchool.billingContact = this.formatEmptyContact();
      } else {
        // eslint-disable-next-line
        _.forEach(school.contacts, contact => {
          let key = null;
          switch (contact.type) {
            case 'billing':
              key = 'billingContact';
              break;
            default:
              key = 'primaryContact';
              break;
          }
          formattedSchool[key] = this.formatContact(contact);
        });
      }

      formattedSchool.billingSameAsPrimary = this.billingIsSameAsPrimary(formattedSchool.billingContact, formattedSchool.primaryContact, ['id', 'type', 'created_at', 'updated_at']);

      return formattedSchool;
    },
    formatContact(contact) {
      const formattedContact = {};
      formattedContact.id = contact.id;
      formattedContact.name = contact.contact_name;
      formattedContact.job_role = contact.job_role;
      formattedContact.address = {};
      formattedContact.address.line1 = contact.address_line_1;
      formattedContact.address.line2 = contact.address_line_2;
      formattedContact.postcode = contact.postcode;
      formattedContact.countryCode = contact.country_code;
      formattedContact.region = contact.region;
      formattedContact.city = contact.city;
      formattedContact.county = contact.county;
      formattedContact.phone = contact.telephone;
      formattedContact.email = contact.email;
      formattedContact.country = contact.country;

      return formattedContact;
    },
    formatEmptyContact() {
      const formattedContact = {};
      formattedContact.id = null;
      formattedContact.name = null;
      formattedContact.job_role = null;
      formattedContact.address = {};
      formattedContact.address.line1 = null;
      formattedContact.address.line2 = null;
      formattedContact.postcode = null;
      formattedContact.countryCode = null;
      formattedContact.region = null;
      formattedContact.city = null;
      formattedContact.county = null;
      formattedContact.phone = null;
      formattedContact.email = null;

      return formattedContact;
    },
    flattenContacts(school) {
      const flatContacts = [];
      flatContacts.push(this.flattenContact(school.primaryContact, 'primary'));
      if (!this.school.billingSameAsPrimary) {
        flatContacts.push(this.flattenContact(school.billingContact, 'billing'));
      } else { // else we duplicate the primary contact as the billing one
        school.primaryContact.id = school.billingContact.id;
        flatContacts.push(this.flattenContact(school.primaryContact, 'billing'));
      }

      return flatContacts;
    },
    flattenContact(contact, type) {
      return {
        id: contact.id,
        type,
        contact_name: contact.name,
        job_role: contact.job_role,
        address_line_1: contact.address.line1,
        address_line_2: contact.address.line2,
        postcode: contact.postcode,
        country_code: contact.countryCode,
        region: contact.region,
        city: contact.city,
        county: contact.county,
        telephone: contact.phone,
        email: contact.email,
      };
    },
    checkIfBillingIsSameAsPrimary(school) {
      return false;
      // eslint-disable-next-line no-unreachable
      const billingContact = this._.cloneDeep(school.billingContact);
      delete billingContact.id;
      const primaryContact = this._.cloneDeep(school.primaryContact);
      delete primaryContact.id;

      return this._.isEqual(billingContact, primaryContact);
    },
    extractGroupsFromSchool(school) {
      const groups = [];
      this._.each(school.groups, (group) => {
        groups.push(group.name);
      });
      return this._.uniq(groups);
    },
    getDefaultSchool() {
      return {
        name: '',
        urn: '',
        primaryContact: {
          name: this.$store.state.user ? `${this.$store.state.user.first_name} ${this.$store.state.user.last_name}` : '',
          address: {
            line1: '',
            line2: '',
            postcode: '',
            countryCode: 'GBR',
          },
          phone: '',
          email: this.$store.state.user ? this.$store.state.user.email : '',
        },
        billingContact: {
          name: '',
          address: {
            line1: '',
            line2: '',
            postcode: '',
            countryCode: 'GBR',
          },
          phone: '',
          email: '',
        },
        billingSameAsPrimary: true,
      };
    },
    billingIsSameAsPrimary(obj1, obj2, excludeKeys = []) {
      const keys1 = _.omit(obj1, excludeKeys);
      const keys2 = _.omit(obj2, excludeKeys);

      if (!_.isEqual(Object.keys(keys1), Object.keys(keys2))) {
        return false;
      }

      return _.every(keys1, (value, key) => _.isEqual(value, obj2[key]));
    },
  },
};
