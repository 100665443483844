<template>
    <div class="container container-table">
        <div class="row">
            <div class="col-md-3 col-sm-1"></div>
            <div class="col-md-6 col-sm-10">
                <div class="box-front">
                    <img src="../assets/img/fonetti-logo-new.png" class="center-block logo">
                    <p class="text-center text-primary">Validating Subscription...</p>
                </div>
            </div>
        </div>
        <div class="col-md-3 col-sm-1"></div>
    </div>
</template>
<script>
import SubscriptionMixin from '@/components/mixins/SubscriptionMixin';

export default {
  mixins: [SubscriptionMixin],
  mounted() {
    const view = this.$route.query.view || 0;
    if (!this.$store.state.hasSubscription) {
      if (!view) {
        this.handleSubscriptionProcess()
          .then((path) => {
            this.$router.push(path);
          })
          .catch(() => {
            this.$router.push('/logout');
          });
      }
    } else {
      this.$router.push('/');
    }
  },
};
</script>
