<template>
  <div class="container container-table invite-page">
    <div class="row">
      <div class="col-md-3 col-sm-1"></div>
      <div class="col-md-6 col-sm-10">
        <div class="row">
          <div class="col-centered col-md-10">
            <div class="box-front" v-if="hasInvite">
              <img src="../assets/img/fonetti-logo-new.png" class="center-block logo">
              <action-messages></action-messages>
              <p class="callout">You have been invited to Fonetti. Please choose one of the following:</p>
              <ul class="choices">
                <li><button class="btn btn-primary btn-lg" @click="redirect('/login')">Login</button></li>
                <li><button class="btn btn-primary btn-lg" @click="redirect('/register')">Register</button></li>
                <li><button class="btn btn-link btn-lg" @click="decline()">Decline</button></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-sm-1"></div>
    </div>
  </div>
</template>

<script>
import CurrentUserMixins from '@/components/mixins/CurrentUserMixins';
import SubscriptionMixin from '@/components/mixins/SubscriptionMixin';
import InviteMixin from '@/components/mixins/InviteMixin';

export default {
  name: 'Invite',
  data() {
    return {
      section: 'Invite',
      errorMsg: null,
      isLoading: false,
      hasInvite: false,
    };
  },
  mixins: [CurrentUserMixins, SubscriptionMixin, InviteMixin],
  mounted() {
    this.validateInvite();
  },
  methods: {
    validateInvite() {
      this.$http.get(`enterprises/invites/${this.$route.params.id}`)
        .then((response) => {
          const { invite } = response.data;
          const inviteStatus = invite.status;
          if (inviteStatus !== 'pending') {
            this.$router.replace('/login');
            return;
          }

          this.hasInvite = true;
          window.localStorage.setItem('invitee_email', invite.email);
        })
        .catch((error) => {
          this.$apiResponse.renderErrorMessage(error);
          this.currentStep = null;
        });
    },
    redirect(url) {
      this.setOrigin();
      this.$router.push(url);
    },
    setOrigin() {
      window.localStorage.setItem('invite', this.$route.params.id);
    },
  },
};
</script>
