var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('form',{ref:"observer",staticClass:"ui form",on:{"submit":function($event){$event.preventDefault();return passes(_vm.validateForm)}}},[_c('ValidationProvider',{attrs:{"rules":{ required: true, min: 2, },"name":"First Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group",class:{ 'has-error': errors[0] }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.mutableValue.first_name),expression:"mutableValue.first_name"}],staticClass:"form-control",attrs:{"id":"first_name","name":"first_name","placeholder":"First name","type":"text"},domProps:{"value":(_vm.mutableValue.first_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.mutableValue, "first_name", $event.target.value)}}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors),expression:"errors"}],staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":{ required: true, min: 2 },"name":"Last Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group",class:{ 'has-error': errors[0] }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.mutableValue.last_name),expression:"mutableValue.last_name"}],staticClass:"form-control",attrs:{"id":"last_name","name":"last_name","placeholder":"Last name","type":"text"},domProps:{"value":(_vm.mutableValue.last_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.mutableValue, "last_name", $event.target.value)}}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors),expression:"errors"}],staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required|email","name":"Email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group",class:{ 'has-error': errors[0] }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.mutableValue.email),expression:"mutableValue.email"}],staticClass:"form-control",attrs:{"id":"email","name":"email","placeholder":"Email","type":"email"},domProps:{"value":(_vm.mutableValue.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.mutableValue, "email", $event.target.value)}}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors),expression:"errors"}],staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Job Role"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group",class:{ 'has-error': errors[0] }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.mutableValue.job_role),expression:"mutableValue.job_role"}],staticClass:"form-control",attrs:{"id":"job_role","name":"email","placeholder":"Job Role","type":"text"},domProps:{"value":(_vm.mutableValue.job_role)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.mutableValue, "job_role", $event.target.value)}}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors),expression:"errors"}],staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":{ required: true, min: 7 },"name":"Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group",class:{ 'has-error': errors[0] }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.mutableValue.password),expression:"mutableValue.password"}],staticClass:"form-control",attrs:{"id":"password","name":"password","placeholder":"Password","type":"password"},domProps:{"value":(_vm.mutableValue.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.mutableValue, "password", $event.target.value)}}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors),expression:"errors"}],staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('loading-button',{staticClass:"btn-block",attrs:{"is-loading":_vm.loading}},[_vm._v("Register")])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }