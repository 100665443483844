import axios from 'axios';
import config from '../config';

axios.defaults.baseURL = config.serverURI;

export default {
  async login({ commit, dispatch }, credentials) {
    return new Promise((resolve, reject) => {
      axios.post('auth', credentials)
        .then((res) => {
          commit('SET_EMAIL_ADDRESS', credentials.email);
          const { user, meta } = res.data;
          /* Setting user in the state and caching record to the localStorage */
          if (user) {
            const { token } = meta;
            dispatch('setToken', token);

            resolve(user);
          }
          reject(new Error('Login failed'));
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  logout({ commit, dispatch }) {
    commit('SET_EMAIL_ADDRESS', null);
    commit('SET_TOKEN', null);
    if (window.localStorage) {
      window.localStorage.clear('token');
    }
    dispatch('destroyUser');
  },
  setToken({ commit }, token) {
    commit('SET_TOKEN', token);
    if (window.localStorage) {
      window.localStorage.setItem('token', token);
    }
    axios.defaults.headers.common['X-Auris-Token'] = token;
  },
  destroyToken({ commit }) {
    commit('SET_TOKEN', null);
    delete axios.defaults.headers.common['X-Auris-Token'];
    if (window.localStorage) {
      window.localStorage.removeItem('token');
    }
  },
  setUser({ commit }, user) {
    commit('SET_USER', user);
    commit('SET_CURRENT_SCHOOL', user.enterprise);
    if (window.localStorage) {
      window.localStorage.setItem('user', JSON.stringify(user));
    }
  },
  setRowsPerPage({ commit }, n) {
    commit('SET_ROWS_PER_PAGE', n);
  },
  setDefaultDateRange({ commit }, dateRange) {
    commit('SET_DEFAULT_DATE_RANGE', dateRange);
  },
  setEmail({ commit }, email) {
    commit('SET_EMAIL_ADDRESS', email);
  },
  setOrigin({ commit }, origin) {
    commit('SET_ORIGIN', origin);
  },
  clearUser({ commit }) {
    commit('SET_USER', null);
    commit('SET_CURRENT_SCHOOL', null);
    if (window.localStorage) {
      window.localStorage.removeItem('user');
    }
  },
  destroyUser({ commit, dispatch }) {
    dispatch('clearUser');
    dispatch('destroyToken');
    commit('ACCEPT_TERMS_AND_CONDITIONS', false);
  },
  setCurrentGroup({ commit }, group) {
    commit('SET_CURRENT_GROUP', group);
  },
  fillCountries({ commit }, countries) {
    console.log('COUCOU');
    commit('FILL_COUNTRIES', countries);
  },
  clearCurrentGroup({ dispatch }) {
    dispatch('setCurrentGroup', null);
  },
  setCurrentPupil({ commit }, { id, name }) {
    commit('SET_CURRENT_PUPIL', { id, name });
  },
  clearCurrentPupil({ dispatch }) {
    dispatch('setCurrentPupil', { id: null, name: null });
  },
  setCurrentBook({ commit }, { id, title }) {
    commit('SET_CURRENT_BOOK', { id, title });
  },
  clearCurrentBook({ dispatch }) {
    dispatch('setCurrentBook', { id: null, title: null });
  },
};
