<template>
  <div>
    <modal title="Book Info" v-if="modal.isVisible('viewBookInfo')" @close="modal.hide('viewBookInfo')" :show-footer="false">
      <book-details :book="chosenBook"></book-details>
    </modal>
    <modal title="Unassign Book" v-if="modal.isVisible('unassign')" @close="modal.hide('unassign')">
      <unassign-modal-content
        @yes="handleRemoveBook()"
        @no="modal.hide('unassign')"
      />
    </modal>
    <div class="row">
      <div class="col-md-12">
        <div class="row" v-if="currentEntityType === EntityType.PUPIL">
          <div class="col-md-6">
            <h2 class="panel-heading">{{pupilName}}</h2>
          </div>
        </div>
        <div class="shuffle">
          <div class="row d-flex align-items-start">
            <div class="col-md-9">
              <div class="search form-inline">
                <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48" class="icon"><path d="M795.435-105.173 529.043-370.999q-29.434 24.26-69.111 37.934-39.676 13.674-85.323 13.674-112.119 0-189.864-77.826Q106.999-475.043 106.999-585t77.827-187.783q77.826-77.826 188.283-77.826 110.456 0 187.782 77.826 77.327 77.826 77.327 187.933 0 43.98-13.152 83.133-13.153 39.152-39.457 73.587l267.392 265.391-57.566 57.566ZM373.808-398.609q77.659 0 131.425-54.533Q558.999-507.674 558.999-585q0-77.326-53.849-131.858-53.849-54.533-131.342-54.533-78.326 0-132.958 54.533Q186.218-662.326 186.218-585q0 77.326 54.549 131.858 54.549 54.533 133.041 54.533Z"/></svg>
                <input type="search" class="form-control" placeholder="Search for a book" v-model="searchTerm">
              </div>
            </div>
            <div class="col-md-3">
              <div class="filters">
                <div class="reading_level_filter">
                  <label for="reading_level_start">Reading Levels from</label>
                  <select name="reading_level_start" id="reading_level_start" v-model="levelStart" @click="rangeSelectedFromInput=true">
                    <option v-for="(txt, n) in readingLevelRangeOptions" :value="n" :key="n" :disabled="n > levelEnd">{{ txt }}</option>
                  </select>
                  <label for="reading_level_end">To</label>
                  <select name="reading_level_end" id="reading_level_end" v-model="levelEnd" @click="rangeSelectedFromInput=true">
                    <option v-for="(txt, n) in readingLevelRangeOptions" :value="n" :key="n" :disabled="n < levelStart">{{ txt }}</option>
                  </select>
                </div>
                <div class="quiz_filter">
                  <div class="quiz_input"><input type="checkbox" id="show_only_quiz" v-model="showOnlyBooksWithQuestions"/></div><label for="show_only_quiz">Only books with quizzes</label>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="tags">
                <div class="btn-group" role="group">
                  <button class="btn btn-primary" :class="{'active': tag === 'all'}" @click="filter('all')">All books
                  </button>
                  <button class="btn btn-primary" :class="{'active': tag === 'assigned'}" @click="filter('assigned')">Only
                    Assigned
                  </button>
                  <button class="btn btn-primary" :class="{'active': tag === 'unassigned'}" @click="filter('unassigned')">Only
                    Unassigned
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="list-actions-container">
                <div class="list-actions">
                  <button @click="bulkAssign" class="btn btn-assign-remove btn-success" :disabled="assigningBook"><i class="fa" :class="assigningBook ? 'fa-spinner fa-pulse' : 'fa-plus'"></i> Assign all on view</button>
                  <button @click="startBulkUnassign()" class="btn btn-assign-remove btn-danger" :disabled="removingBook"><i class="fa" :class="removingBook ? 'fa-spinner fa-pulse' : 'fa-minus'"></i> Remove all on view</button>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="libraries mb-5">
                <div class="btn-group" role="group">
                  <template v-for="(library, index) in Object.keys(library_config)">
                    <button :key="index" v-if="libraries[library].length" class="btn btn-primary" :class="{'active': selectedLibrary === library}" @click="selectedLibrary = library">
                      <img :class="library_config[library].imgClass || []" v-if="library_config[library].logo" :src="library_config[library].logo">
                      <span>{{ library_config[library].title }}</span>
                    </button>
                  </template>
                  <button v-if="libraries.others.length" class="btn btn-primary" :class="{'active': selectedLibrary === 'others'}" @click="selectedLibrary = 'others'">
                    <span>Other Books</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 text-center">
              <a class="btn btn-primary" target="_blank" :href="selectedLibraryConfig.infoDoc || ''" v-if="'infoDoc' in selectedLibraryConfig">View Book Leveling Information</a>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
            <div v-for="(group, index) in groupedBooks" :key="index" class="list-container">
              <h2 class="list-title">{{group.group_title}}</h2>
              <transition-group name="list" class="list" tag="section">
              <div v-for="(book, index) in group.books" :key="book.id" class="list-item" :class="{ 'assigned': book.is_assigned, 'unassigned': !book.is_assigned, 'disabled': !book.is_assignable }">
                <div class="list-item-inner">
                  <div class="lock" v-if="!book.is_assignable">
                    <i class="fa fa-lock"></i>
                  </div>
                  <img :src="fixCoverImageUrl(book.cover_image)" alt="" class="list-img">
                  <div class="title">{{ book.title }}</div>
                  <div class="icon-quiz" v-if="book.questions_sheet_url"><svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M543-340q16.595 0 28.798-12.202Q584-364.405 584-381t-12.202-28.798Q559.595-422 543-422t-28.798 12.202Q502-397.595 502-381t12.202 28.798Q526.405-340 543-340Zm-25-126h47q2-29 8.5-43t32.5-39q27-26 37.5-45.395Q654-612.79 654-639q0-45.882-31.5-74.941Q591-743 540-743q-38 0-68 20.5T428-665l45 19q11-25 27.5-38t39.5-13q29.778 0 48.389 17Q607-663 607-637q0 20-9 35t-32 32q-32 29-40 46.5t-8 57.5ZM260-200q-24 0-42-18t-18-42v-560q0-24 18-42t42-18h560q24 0 42 18t18 42v560q0 24-18 42t-42 18H260Zm0-60h560v-560H260v560ZM140-80q-24 0-42-18t-18-42v-620h60v620h620v60H140Zm120-740v560-560Z"/></svg></div>
                  <div v-if="currentEntityType === 0">
                    <div class="overlay" v-if="book.is_assigned_via_group">
                      <div class="inner">
                        <button @click="startUnassignBook(book.id, index)" class="btn btn-assign-remove-single" :class="removingBook ? 'btn-link' : 'btn-danger'" :disabled="removingBook"><i class="fa" :class="removingBook ? 'fa-spinner fa-pulse' : 'fa-minus'"></i> Remove from class</button>
                        <book-buttons @show-book-details="showBookDetails(book)" @open-preview="openPreview" @open-questions="openQuestions" @open-answers="openAnswers" :book="book"/>
                      </div>
                    </div>
                    <div class="overlay" v-else>
                      <div class="inner">
                        <button v-if="book.is_assignable" @click="assignBook({entityType: currentEntityType, id: book.id, index: index, pupilId: pupilId, groupId: groupId})" class="btn btn-assign-remove-single" :class="assigningBook ? 'btn-link' : 'btn-success'" :disabled="assigningBook"><i class="fa" :class="assigningBook ? 'fa-spinner fa-pulse' : 'fa-plus'"></i> Assign to class</button>
                        <div class="text-center" v-else>This book is not available as part of the trial.</div>
                        <book-buttons @show-book-details="showBookDetails(book)" @open-preview="openPreview" @open-questions="openQuestions" @open-answers="openAnswers" :book="book"/>
                      </div>
                    </div>
                  </div>
                  <div v-if="currentEntityType === 1">
                    <div class="overlay" v-if="book.is_assigned_via_group">
                      <div class="inner">
                        <p>This book is assigned through class</p>
                        <book-buttons @show-book-details="showBookDetails(book)" @open-preview="openPreview" @open-questions="openQuestions" @open-answers="openAnswers" :book="book"/>
                      </div>
                    </div>
                    <div class="overlay" v-else-if="book.is_assigned_to_reader">
                      <div class="inner">
                        <button @click="startUnassignBook(book.id, index)"  class="btn btn-assign-remove-single" :class="removingBook ? 'btn-link' : 'btn-danger'" :disabled="removingBook"><i class="fa" :class="removingBook ? 'fa-spinner fa-pulse' : 'fa-minus'"></i> Remove from {{ entityTypeString }}</button>
                        <book-buttons @show-book-details="showBookDetails(book)" @open-preview="openPreview" @open-questions="openQuestions" @open-answers="openAnswers" :book="book"/>
                      </div>
                    </div>
                    <div class="overlay" v-else>
                      <div class="inner">
                        <button v-if="book.is_assignable" @click="assignBook({entityType: currentEntityType, id: book.id, index: index, pupilId: pupilId, groupId: groupId})" class="btn btn-assign-remove-single" :class="assigningBook ? 'btn-link' : 'btn-success'" :disabled="assigningBook"><i class="fa" :class="assigningBook ? 'fa-spinner fa-pulse' : 'fa-plus'"></i> Assign to {{ entityTypeString }}</button>
                        <div class="text-center" v-else>This book is not available as part of the trial.</div>
                        <book-buttons @show-book-details="showBookDetails(book)" @open-preview="openPreview" @open-questions="openQuestions" @open-answers="openAnswers" :book="book" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </transition-group>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BooksMixin from '@/components/mixins/BooksMixin';
import Modal from '@/components/classes/Modal';
import ModalJS from '@/components/utils/Modal';
import BookDetails from '@/components/partials/BookDetails';
import UnassignModalContent from '@/components/partials/UnassignModalContent';
import BookButtons from '@/components/partials/BookButtons';
import FonettiLogo from '@/assets/img/FonettiLibrary.png';
import LadybirdLogo from '@/assets/img/icons/ladybird.svg';
import CornerstonesLogo from '@/assets/img/icons/cornerstones.svg';
import SensationsEnglishLogo from '@/assets/img/icons/sensations-english-icon.png';
import OneDecisionLogo from '@/assets/img/icons/1decision-icon.png';

// const BOOK_GROUP_TITLES = {
//   edubrisk: 'EduBrisk Titles',
//   'fonetti-premium': 'Fonetti Titles',
//   'poems-and-pictures-ltd': 'Poems and stories by Christina Gabbitas',
//   'martin-roberts-the-villes': 'The Villes by Martin Roberts',
//   'various-single-purchases': 'Various Authors',
//   cornerstones: 'Cornerstones',
//   default: 'Additional Titles',
// };

// eslint-disable-next-line
const LIBRARIES = {
  fonetti: {
    collection_keys: ['fonetti-premium'],
    title: 'Fonetti Library',
    logo: FonettiLogo,
    imgClass: ['fonetti-logo'],
    infoDoc: 'https://d3vlekh17wu64n.cloudfront.net/collection_assets/Fonetti Level Explainer.pdf',
  },
  ladybird: {
    collection_keys: ['ladybird-trial', 'ladybird-full'],
    title: 'Ladybird Library',
    logo: LadybirdLogo,
    infoDoc: 'https://d3vlekh17wu64n.cloudfront.net/collection_assets/Ladybird Level Explainer.pdf',
  },
  cornerstones: {
    collection_keys: ['cornerstones'],
    title: 'Cornerstones Library',
    logo: CornerstonesLogo,
  },
  sensationsenglish: {
    collection_keys: ['sensations-english'],
    title: 'Sensations English Library',
    logo: SensationsEnglishLogo,
    groups: {
      7: 'A2',
      8: 'B1',
      9: 'B1+',
      10: 'B2',
      11: 'C1',
    },
    infoDoc: 'https://d3vlekh17wu64n.cloudfront.net/collection_assets/Sensations English Level Explainer.pdf',
  },
  '1decision': {
    collection_keys: ['1decision'],
    title: '1decision Library',
    logo: OneDecisionLogo,
  },
};

const DEFAULT_READING_LEVEL_RANGE = Array.from({ length: 13 }, (_, i) => i);

export default {
  name: 'library-info',
  mixins: [BooksMixin],
  components: {
    modal: ModalJS,
    UnassignModalContent,
    BookDetails,
    BookButtons,
  },
  props: {
    books: {
      type: Array,
      required: true,
    },
    currentEntityType: {
      type: Number,
      required: true,
    },
    pupilName: {
      required: true,
    },
    groupId: {
      required: false,
      type: Number,
    },
  },
  data() {
    return {
      tag: 'all',
      removingBook: false,
      removingBooks: false,
      assigningBook: false,
      searchTerm: '',
      showOnlyBooksWithQuestions: false,
      chosenBook: null,
      shouldShowRemoveBookWarning: true,
      modal: new Modal({
        viewBookInfo: false,
        unassign: false,
      }),
      bookToRemoveId: null,
      bookToRemoveIndex: null,
      selectedLibrary: Object.keys(LIBRARIES)[0],
      levelStart: 0,
      levelEnd: 13,
      readingLevelRange: DEFAULT_READING_LEVEL_RANGE,
      rangeSelectedFromInput: false,
      libraries: {
        fonetti: [],
        ladybird: [],
        cornerstones: [],
        sensationsenglish: [],
        others: [],
      },
      library_config: LIBRARIES,
    };
  },
  methods: {
    openPreview(book) {
      window.open(book.book_preview_url);
    },
    openQuestions(book) {
      window.open(book.questions_sheet_url);
    },
    openAnswers(book) {
      window.open(book.answers_sheet_url);
    },
    updateBookToRemove(bookInfo) {
      const { id, index } = bookInfo;
      this.bookToRemoveId = id;
      this.bookToRemoveIndex = index;
    },
    handleRemoveBook() {
      this.startRemoveBookWarningTimeOut();
      if (this.removingBooks) {
        this.bulkUnassign();
      } else {
        this.removeBook({
          entityType: this.currentEntityType,
          id: this.bookToRemoveId,
          index: this.bookToRemoveIndex,
          pupilId: this.pupilId,
          schoolId: this.schoolId,
          groupId: this.groupId,
        });
      }
      this.modal.hide('unassign');
    },
    handleCloseUnassignModal() {
      this.updateBookToRemove({ id: null, index: null });
      this.removingBooks = false;
      this.modal.hide('unassign');
    },
    startRemoveBookWarningTimeOut() {
      this.shouldShowRemoveBookWarning = false;
      setTimeout(() => {
        this.shouldShowRemoveBookWarning = true;
      }, 300000);
    },
    resetBookToRemove() {
      this.bookToRemoveId = null;
      this.bookToRemoveIndexindex = null;
    },
    startUnassignBook(id, index) {
      this.bookToRemoveId = id;
      this.bookToRemoveIndex = index;
      if (this.shouldShowRemoveBookWarning) {
        this.modal.show('unassign');
      } else {
        this.removeBook({
          entityType: this.currentEntityType, id, index, pupilId: this.pupilId, groupId: this.groupId,
        });
      }
    },
    startBulkUnassign() {
      this.removingBooks = true;
      this.modal.show('unassign');
    },
    filter(tag) {
      this.tag = tag;
    },
    showBookDetails(book) {
      this.chosenBook = book;
      this.modal.show('viewBookInfo');
    },
    bulkAssign() {
      const bookIds = this.filteredBooks.filter((b) => b.is_assignable).map((b) => b.id);
      this.assignBooks({
        entityType: this.currentEntityType, bookIds, pupilId: this.pupilId, groupId: this.groupId,
      });
    },
    bulkUnassign() {
      this.removingBooks = false;
      const bookIds = this.filteredBooks.filter((b) => b.is_assignable).map((b) => b.id);
      this.removeBooks({
        entityType: this.currentEntityType, bookIds, pupilId: this.pupilId, groupId: this.groupId,
      });
    },
    recalculatetReadingLevelsRange(data) {
      if (data.length === 0) {
        this.levelStart = 0;
        this.levelEnd = 12;
        this.readingLevelRange = DEFAULT_READING_LEVEL_RANGE;
        return;
      }

      const levels = data.map((item) => item.reading_level);
      const minLevel = Math.min(...levels);
      const maxLevel = Math.max(...levels);

      this.readingLevelRange = Array.from({ length: maxLevel - minLevel + 1 }, (_, index) => minLevel + index);
      if (this.levelEnd < minLevel || this.levelEnd > maxLevel) {
        this.rangeSelectedFromInput = false;
        this.levelEnd = maxLevel;
      }
      if (this.levelStart < minLevel || this.levelStart > maxLevel) {
        this.rangeSelectedFromInput = false;
        this.levelStart = minLevel;
      }

      if (!this.rangeSelectedFromInput) {
        this.levelStart = minLevel;
        this.levelEnd = maxLevel;
      }
    },
  },
  computed: {
    filteredBooks() {
      const selectedBooks = this.libraries[this.selectedLibrary];
      const re = new RegExp(this.searchTerm, 'i');
      const shouldBeAssigned = this.tag === 'assigned';
      let newArray = selectedBooks.filter((book) => re.test(book.title)
            && (this.tag === 'all' || book.is_assigned === shouldBeAssigned)
            && (!this.showOnlyBooksWithQuestions || book.questions_sheet_url !== null));
      this.recalculatetReadingLevelsRange(newArray);
      newArray = newArray.filter((book) => book.reading_level >= this.levelStart && book.reading_level <= this.levelEnd);
      this.$emit('filtered-array-update', newArray);
      return newArray;
    },
    groupedBooks() {
      const grouped = {};

      this.filteredBooks.forEach((book) => {
        const readingLevel = book.reading_level;

        if (!grouped[readingLevel]) {
          if ('groups' in this.selectedLibraryConfig) {
            grouped[readingLevel] = {
              reading_level: readingLevel,
              group_title: this.selectedLibraryConfig.groups[book.reading_level],
              books: [],
            };
          } else {
            grouped[readingLevel] = {
              reading_level: readingLevel,
              group_title: `Reading Level ${readingLevel}`,
              books: [],
            };
          }
        }

        grouped[readingLevel].books.push(book);
      });

      return grouped;
    },
    pupilId() {
      return this.$route.params.id;
    },
    entityTypeString() {
      return this.currentEntityType === this.EntityType.CLASS ? 'class' : 'pupil';
    },
    readingLevelRangeOptions() {
      if ('groups' in this.selectedLibraryConfig) {
        return this.readingLevelRange.reduce((acc, cur) => {
          acc[cur] = this.selectedLibraryConfig.groups[cur];
          return acc;
        }, {});
      }
      return this.readingLevelRange.reduce((acc, cur) => { acc[cur] = cur; return acc; }, {});
    },

    selectedLibraryConfig() {
      return this.library_config[this.selectedLibrary];
    },

  },
  watch: {
    books: {
      // eslint-disable-next-line
      handler: function(data) {
        const libraries = Object.keys(LIBRARIES);
        let keysWithLibraries = [];
        libraries.forEach((library) => {
          const libraryObj = LIBRARIES[library];
          keysWithLibraries = [...keysWithLibraries, ...libraryObj.collection_keys];
          this.libraries[library] = data.filter((book) => libraryObj.collection_keys.includes(book.collection_key));
        });
        this.libraries.others = data.filter((book) => !keysWithLibraries.includes(book.collection_key));
      },
      immediate: true,
    },
  },
};
</script>
<style scoped lang="scss">
  .background {
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    align-items: center;
    overflow: hidden;
    justify-content: center;
  }
  .background img {
    width: 100%;
  }
  .shuffle .list-item {
    height: 250px;
    /*position: relative;*/
  }
  .shuffle .assigned {
    border: 2px solid #2ecc71;
  }
  .shuffle .unassigned {
    border: 2px solid #f1c40f;
  }
  .shuffle .disabled {
    border: 2px solid #c6c6c6;
  }
  .shuffle .list-item .list-item-inner {
    position: relative;
    height: 100%;
  }
  .shuffle .list-item .title {
    position: absolute;
    bottom: 0;
    background: rgba(0, 0, 0, .6);
    width: 100%;
    color: #fff;
    font-weight: 700;
    padding: .25em .5em;
    text-shadow: 1px 1px 0 rgba(0, 0, 0, .75);
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
  }
  .shuffle .list-item .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    background: rgba(255, 255, 255, .9);
    width: 100%;
    /*opacity: 0;*/
    display: none;
    -webkit-transition: all .3s;
    transition: all .3s;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  .shuffle .list-item:hover .overlay {
    /*opacity: 1;*/
    display: block;
  }
  .shuffle .list-item .overlay .inner {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 70%;
    height: 100%;
    margin:auto;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  .shuffle .btn-primary {
    padding: 6px 12px;
    text-transform: none;
    font-size: 14px;
    font-weight: normal;
    margin-top: 5px;
  }
  .shuffle .btn-primary .icon::before {
    vertical-align: -1px;
  }
  .list-container:not(:first-child) {
    margin-top:8em;
  }
  .list-title {
    font-weight:300;
    margin: 0 4px 0.2em 8px;
  }
  .btn-group {
    .btn-primary:not(.active) {
      border: 1px solid #249bb6;
    }
  }
  .list {
    &-actions {
      display: flex;
      justify-content: space-between;
      button:first-child {
        margin-right: 5px;
      }
      &-container {
        display: flex;
        justify-content: flex-end;
      }
    }
  }
  .icon-quiz {
    position:absolute;
    top:0.5em;
    right: 0.5em;
    border-radius: 100px;
    background: white;
    padding: 0.3em;
    width: 2.3em;
    height: 2.3em;
    box-shadow: 1px 1px 0px 1px #0000006b;
  }
  .icon-quiz > svg {
    width:100%;
    height:100%;
  }
  .shuffle .search {
    display: flex;
    align-items: center;
  }
  .shuffle .search .icon {
    height: 1.6em;
    width:auto;
  }
  .shuffle .search input {
    font-size: 1.6em;
    height: auto;
  }

  .libraries {
    .btn-group {
      .btn {
        display: flex;
        align-items: center;
        height: 50px;
        font-size: 18px;
        img {
          &.fonetti-logo {
            height: 25px;
          }
          &:not(.fonetti-logo) {
            width: 30px;
          }
          margin-right: 12px;
        }
      }
    }
  }

  .list-item.disabled {
    filter: grayscale(1) opacity(0.7);
    .lock {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      .fa {
        font-size: 80px;
        color: #fff;
        text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.4);
      }
    }
  }
</style>
