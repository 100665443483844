export default {
  getCurrentGroupName: (state) => (state.currentGroup ? state.currentGroup.name : null),
  getCurrentGroupId: (state) => (state.currentGroup ? state.currentGroup.id : null),
  getCurrentSchoolId: (state) => (state.currentSchool ? state.currentSchool.id : state.user && state.user.enterprise ? state.user.enterprise.id : null),
  getCurrentSchoolName: (state) => (state.currentSchool ? state.currentSchool.name : state.user && state.user.enterprise ? state.user.enterprise.name : ''),
  getDefaultDateRange: (state) => {
    if (state.defaultDateRange === null) {
      return JSON.parse(window.localStorage.getItem('default_date_range'));
    }

    return state.defaultDateRange;
  },
  isLoggedIn: (state) => !!state.token,
};
