<template>
  <!-- Main content -->
  <section class="content">
    <modal title="Download Pupils Logins" v-if="modal.isVisible('links')" @close="closeLinksModal()">
      <pupils-links v-model="termsAndConditionsAccepted" :has-failed="termsAndConditionsMissing" :links="links"></pupils-links>
    </modal>
    <modal title="Password reset has started" :showFooter="true" v-if="modal.isVisible('email')" @close="modal.hide('email')" >
      <p class="text-muted">We are resetting your selected pupils passwords now and will send you the login details to your email inbox in just a few minutes.</p>
    </modal>
    <!--Manage pupil Modal-->
    <modal title="Manage pupils" v-if="modal.isVisible('manage-pupils')" @close="modal.hide('manage-pupils')" :show-footer="false">
      <p>Would you like to add an existing school pupil or create a new pupil account?</p>
      <hr>
      <button class="btn btn-primary" @click="showAddExistingPupilModal('input.multiselect__input')">Add existing pupils</button>
      <button class="btn btn-primary pull-right" @click="$router.push({path: `/classes/${groupId}/add-readers`})">Create new pupil accounts</button>
      <div class="clearfix"></div>
    </modal>
    <!--Move pupil Modal-->
    <modal title="Change Class" v-if="modal.isVisible('move-pupils')" @close="modal.hide('move-pupils')" :show-footer="false">
      <form class="ui form teacher-form form-horizontal" @submit.prevent="">
        <multiselect v-model="newGroup" :options="availableGroups" placeholder="Select a class" label="id" track-by="id" :custom-label="groupName"></multiselect>
        <hr>
        <div class="form-group">
          <div class="col-sm-offset-10 col-sm-2 text-right">
            <loading-button @click.native="moveToGroup" :is-loading="isLoading">Move</loading-button>
          </div>
        </div>
      </form>
    </modal>
    <!--Pupil Modal-->
    <modal title="Add Pupils" v-if="modal.isVisible('add-pupil')" @close="modal.hide('add-pupil')" :show-footer="false">
      <form class="ui form pupil-form form-horizontal" @submit.prevent="">
        <multiselect
          ref="vms"
          v-model="selectedPupils"
          label="name"
          placeholder="Type to search"
          open-direction="bottom"
          :options="availablePupils"
          :multiple="true"
          :searchable="true"
          :loading="searchingForExistingPupils"
          :internal-search="false"
          track-by="id"
          :clear-on-select="true"
          :close-on-select="false"
          :max-height="600"
          :show-no-results="true"
          :hide-selected="true"
          @search-change="asyncFindPupil"
          @close="pupilsSearchStringTooShort = false"
        >
          <template slot="clear" slot-scope="props">
            <div class="multiselect__clear" v-if="selectedPupils.length" @mousedown.prevent.stop="clearAll(props.search)"></div>
          </template>
          <template slot="noResult">
            <span v-if="pupilsSearchStringTooShort">Please enter at least 2 characters.</span>
            <span v-else>No pupils found. Please check you have entered their name correctly.</span>
          </template>
          <template><span slot="noOptions">Search results will appear here...</span></template>
        </multiselect>
        <hr>
        <div class="form-group">
          <div class="col-sm-offset-10 col-sm-2 text-right">
            <loading-button @click.native="addPupils()" :is-loading="isLoading">Add</loading-button>
          </div>
        </div>
      </form>
    </modal>
    <!--Delete Pupil Modal-->
    <modal title="Remove Pupil" v-if="modal.isVisible('remove-pupil')" @close="modal.hide('remove-pupil')">
      <h3 class="text-center">Remove {{pupilToRemove.name}} from "{{group.name}}"</h3>
      <hr />
      <div class="text-center button-locator">
        <button @click="removePupil()" class="btn btn-lg btn-yes btn-primary">Yes</button>
        <button @click="modal.hide('remove-pupil')" class="btn btn-no btn-lg btn-primary">No</button>
      </div>
    </modal>
    <!--Title-->
    <div class="class-info">
      <div class="class-name">
        <template v-if="isEditingGroup">
          <input class="form-control name" v-model="group.name">
          <i class="fa" :class="isGroupEditing ? 'fa-spinner fa-spin' : 'fa-check'" @click="editGroup"></i>
        </template>
        <template v-else>
          <h2 class="name">{{ group.name }}</h2>
          <i class="fa fa-pencil" @click="isEditingGroup = true"></i>
        </template>
        <!-- <h2 v-if="!isEditingGroup" @dblclick="isEditingGroup = true">
          {{ group.name }}
          <i class="fa fa-pencil" @click="isEditingGroup = true"></i>
        </h2>
        <div v-if="isEditingGroup">
          <div class="form-group col-md-3"><input type="text" class="form-control" v-model="group.name" v-show="isEditingGroup"></div>
          <i class="fa" :class="isGroupEditing ? 'fa-spinner fa-spin' : 'fa-check'" @click="editGroup"></i>
        </div> -->
      </div>
      <div class="assigned-teacher">
        <template v-if="!isEditingTeacher">
          Teacher: <template v-if="group.manager">{{ group.manager }}</template><template v-else>Not assigned</template>
          <i class="fa fa-pencil" @click="isEditingTeacher = true" style="margin-left: 5px"></i>
        </template>
        <template v-else>
          <select v-model="manager_id">
            <option value="0">Not assigned</option>
            <option v-for="teacher in schoolTeachers" :key="teacher.id" :value="teacher.id">{{ teacher.first_name }} {{ teacher.last_name }}</option>
          </select>
          <div class="assigned-teacher__actions">
            <i class="fa fa-check" @click="assignTeacher"></i>
          </div>
        </template>
      </div>
    </div>
    <!--Pupil DataTable -->
    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="pull-right box-tools">
          <loading-button @click="resetPupilPasswords" :is-loading="isLoading" :is-disabled="selectedPupilRows.length <= 0">Reset Passwords</loading-button>
          <loading-button @click="modal.show('move-pupils')" :is-loading="isLoading" :is-disabled="selectedPupilRows.length <= 0">Change Class</loading-button>
          <loading-button @click="modal.show('manage-pupils')" :is-loading="isLoading">Add Pupils</loading-button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="box box-info">
          <div class="box-header with-border">
            <h3 class="box-title">Pupils</h3>
          </div>
          <div class="box-body">
            <vue-good-table
              styleClass="vgt-table striped dataTable"
              theme="auris-admin"
              :columns="columns.pupils"
              :rows="groupPupils"
              :search-options="{
                enabled: true
              }"
              :pagination-options="{
                enabled: true,
                mode: 'pages',
                perPage: parseInt(rowsPerPage),
                position: 'bottom',
                perPageDropdown: rowsPerPageOptions,
                dropdownAllowAll: false,
                setCurrentPage: 1,
                nextLabel: 'next',
                prevLabel: 'prev',
                rowsPerPageLabel: 'Rows per page',
                ofLabel: 'of',
                pageLabel: 'page',
                allLabel: 'All',
              }"
            >
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'checkbox'">
                  <input type="checkbox" v-model="selectedPupilRows" :value="props.row.id" />
                </span>
                <a class="u-hover__pointer" v-else-if="props.column.field == 'name'" @click="goToPupilPage(props.row.id)">
                  {{props.formattedRow[props.column.field]}}
                </a>
                <span v-else-if="props.column.field == 'actions'">
                  <button @click="goToPupilPage(props.row.id)" class="btn btn-link btn-more-info"><i class="fa fa-eye"></i> View Pupil</button>
                  <button @click="manageBooks(props.row.id)" class="btn btn-link btn-icon-book js-assign"><i class="icon icon-book"></i> Manage books</button>
                  <button class="btn btn-link btn-icon-trash delete" @click.prevent="confirmRemovePupil(props.row)">
                    <i :class="props.row.is_being_deleted ? 'fa fa-spinner fa-spin' : 'icon icon-trash'"></i> Remove from class
                  </button>
                </span>
                <span v-else>
                  {{props.formattedRow[props.column.field]}}
                </span>
              </template>
            </vue-good-table>
          </div>
          <loading-state :is-loading="isDataTableLoading"></loading-state>
        </div>
      </div>
    </div>
  </section>
  <!-- /.content -->
</template>

<script>
import Multiselect from 'vue-multiselect';
import Vue from 'vue';
import LoadingButton from '@/components/utils/LoadingButton';
import LoadingState from '@/components/utils/LoadingState';
import ModalJS from '@/components/utils/Modal';
import Modal from '@/components/classes/Modal';
import CurrentUserMixins from '@/components/mixins/CurrentUserMixins';
import ClassesMixin from '@/components/mixins/ClassesMixin';
import SchoolMixin from '@/components/mixins/SchoolMixins';
import PupilsMixin from '@/components/mixins/PupilsMixin';
import PupilsLinks from '@/components/partials/PupilsLinks';
import { VueGoodTable } from 'vue-good-table';
import TableMixin from '@/components/mixins/TableMixin';

export default {
  name: 'Class',
  data() {
    return {
      group: {},
      schoolTeachers: [],
      manager_id: 0,
      pupils: [],
      schoolPupils: [],
      availablePupils: [],
      selectedPupils: [],
      selectedPupilRows: [],
      importTabs: [
        'Single Import',
        'Bulk Import',
      ],
      isEditingGroup: false,
      isEditingTeacher: false,
      modal: new Modal({
        addPupil: false,
        removePupil: false,
        links: false,
        email: false,
        managePupils: false,
        movePupils: false,
      }),
      columns: {
        pupils: [
          {
            label: '',
            field: 'checkbox',
            sortable: false,
            width: '10px',
          },
          {
            label: 'Pupil',
            field: 'name',
            sortable: true,
            firstSortType: 'asc',
          },
          {
            label: 'Actions',
            field: 'actions',
            sortable: false,
            tdClass: 'actions',
          },
        ],
      },
      groupId: null,
      isLoading: false,
      isGroupEditing: false,
      isDataTableLoading: false,
      links: null,
      termsAndConditionsAccepted: false,
      termsAndConditionsMissing: false,
      pupilToAdd: null,
      pupilToRemove: null,
      pupilsSearchStringTooShort: true,
      searchingForExistingPupils: false,
      newGroup: null,
      availableGroups: [],
    };
  },
  mixins: [CurrentUserMixins, ClassesMixin, SchoolMixin, PupilsMixin, TableMixin],
  created() {
    this.$store.dispatch('clearCurrentGroup');
  },
  mounted() {
    this.$nextTick(() => {
      this.school = this.getUserSchool();
      this.isDataTableLoading = true;
      this.getGroupData();
      this.groupId = this.$route.params.id;
    });
  },
  methods: {
    moveToGroup() {
      this.isLoading = true;
      this.$http.post(`enterprises/${this.school.id}/groups/${this.newGroup.id}/readers/move`, { reader_ids: this.selectedPupilRows, source: this.groupId })
        .then(() => {
          this.pupils = this.pupils.filter((obj) => {
            let toKeep = true;
            this.selectedPupilRows.forEach((id) => {
              if (id === obj.id) {
                toKeep = false;
              }
            });
            return toKeep;
          }, this);
          this.selectedPupilRows = [];
        })
        .catch((error) => {
          this.$apiResponse.renderErrorMessage(error);
        })
        .finally(() => {
          this.isLoading = false;
          this.modal.hide('move-pupils');
        });
    },
    showAddExistingPupilModal() {
      this.modal.hide('manage-pupils');
      this.modal.show('add-pupil');
      this.$nextTick(() => {
        this.$refs.vms.$refs.search.focus();
      });
    },
    goToPupilPage(id) {
      this.$router.push(`/classes/${this.groupId}/pupils/${id}`);
    },
    manageBooks(pupilId) {
      this.$router.push(`/classes/${this.groupId}/pupils/${pupilId}/books`);
    },
    resetPupilPasswords() {
      this.isLoading = true;
      this.$http.post(`enterprises/${this.school.id}/readers/password/reset`, { reader_ids: this.selectedPupilRows })
        .then(() => {
          this.isLoading = false;
          this.modal.show('email');
          this.selectedPupilRows = [];
        })
        .catch((error) => {
          this.$apiResponse.renderErrorMessage(error);
          this.isLoading = false;
        });
    },
    getGroupData() {
      this.isLoading = true;
      this.getSchool(this.school.id)
        .then((school) => {
          this.schoolTeachers = school.users;
          this.availableGroups = school.groups;
          return this.getGroup(school.id, this.groupId);
        })
        .then((group) => {
          this.group = group;
          this.$store.dispatch('setCurrentGroup', this.group);
          this.pupils = group.readers ? group.readers : [];
          this.manager_id = group.manager_id ?? 0;
          this.buildPupils(group.readers);

          this.isDataTableLoading = false;
          this.isLoading = false;
        })
        .catch(() => {
          this.isDataTableLoading = false;
          this.isLoading = false;
        });
    },
    buildPupils(groupPupils) {
      // have to do that as data from api is immutable
      const pupils = [];
      this._.each(groupPupils, (pupil) => {
        pupil.is_available = false;
        pupil.is_being_deleted = false;
        pupils.push(pupil);
      });

      this.pupils = pupils;
    },
    editGroup() {
      this.isGroupEditing = true;
      this.$http.post(`enterprises/${this.school.id}/groups/${this.$route.params.id}`, { name: this.group.name })
        .then(() => {
          this.isEditingGroup = false;
          this.isGroupEditing = false;
        })
        .catch((error) => {
          this.isEditingGroup = false;
          this.isGroupEditing = false;
          this.$apiResponse.renderErrorMessage(error);
        });
    },
    confirmRemovePupil(pupil) {
      this.modal.show('remove-pupil');
      this.pupilToRemove = pupil;
    },
    removePupil() {
      if (this.pupilToRemove !== null) {
        // eslint-disable-next-line prefer-destructuring
        const pupilToRemove = this.pupilToRemove; // against race condition
        this.pupilToRemove = null;
        this.updatePupils(pupilToRemove.id, 'is_being_deleted', true);
        this.$http.delete(`enterprises/${this.school.id}/groups/${this.group.id}/readers/${pupilToRemove.id}`)
          .then((response) => {
            this.$apiResponse.renderSuccessMessage(response, 'The pupil has been removed from the class');
            this.updatePupils(pupilToRemove.id, 'is_being_deleted', false);
            this.updatePupils(pupilToRemove.id, 'is_available', true);
          })
          .catch((error) => {
            this.$apiResponse.renderErrorMessage(error);
          });
      }
      this.modal.hide('remove-pupil');
    },
    removeTeacher() {
      this.isLoading = true;
      // eslint-disable-next-line prefer-destructuring
      this.$http.delete(`enterprises/${this.school.id}/groups/${this.group.id}/users`)
        .then((response) => {
          this.$apiResponse.renderSuccessMessage(response, 'The teacher has been removed from the class');
          this.group.manager = null;
        })
        .catch((error) => {
          this.$apiResponse.renderErrorMessage(error);
        })
        .finally(() => {
          this.isLoading = false;
          this.isEditingTeacher = false;
        });
    },
    updatePupils(id, attribute, value) {
      // eslint-disable-next-line consistent-return
      this._.forEach(this.pupils, (pupil, index) => {
        if (pupil.id === id) {
          pupil[attribute] = value;
          Vue.set(this.pupils, index, pupil);
          return false;
        }
      });
    },
    assignTeacher() {
      const userId = parseInt(this.manager_id, 10);
      if (this.group.manager && userId === this.group.manager_id) {
        this.isEditingTeacher = false;
        return;
      }
      if (userId === 0) {
        this.removeTeacher();
        return;
      }
      this.isLoading = true;
      this.$http.post(`enterprises/${this.school.id}/groups/${this.group.id}/users/${userId}`)
        .then((response) => {
          this.$apiResponse.renderSuccessMessage(response, 'The teacher has been assigned to the class');
          const manager = this.schoolTeachers.find((teacher) => teacher.id === userId);
          this.group.manager = manager.full_name;
        })
        .catch((error) => {
          this.$apiResponse.renderErrorMessage(error);
        })
        .finally(() => {
          this.isLoading = false;
          this.isEditingTeacher = false;
        });
    },
    asyncFindPupil(query) {
      if (query.length < 2) {
        this.pupilsSearchStringTooShort = true;
        this.availablePupils = [];
        return;
      }
      this.searchingForExistingPupils = true;
      this.pupilsSearchStringTooShort = false;
      this.$http.get(`enterprises/${this.school.id}/readers?search[value]=${query}&length=50`)
        .then((response) => {
          this.availablePupils = this.getAvailablePupils(response.data, this.pupils);
          this.searchingForExistingPupils = false;
        })
        .catch((error) => {
          this.$apiResponse.renderErrorMessage(error);
          this.modal.hide('add-pupil');
          this.searchingForExistingPupils = false;
        });
    },
    getAvailablePupils(schoolPupils, groupPupils) {
      const availablePupils = [];
      this._.each(schoolPupils, (schoolPupil) => {
        let addPupil = true;
        this._.each(groupPupils, (groupPupil) => {
          if (schoolPupil.id === groupPupil.id && groupPupil.is_available === false) {
            addPupil = false;
          }
        });
        if (addPupil) {
          availablePupils.push(schoolPupil);
        }
      });
      return availablePupils;
    },
    addPupils() {
      if (this._.isEmpty(this.selectedPupils)) {
        return;
      }
      this.isDataTableLoading = true;
      this.isLoading = true;
      this.createPupils(this.selectedPupils, this.school.id, this.group.id)
        .then((links) => {
          if (links) {
            this.links = links;
          }

          this.getGroupData();
          this.modal.hide('add-pupil');
          if (!this._.isEmpty(this.links)) {
            this.modal.show('links');
          }
        })
        .catch(() => {
          this.modal.hide('add-pupil');
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    groupName({ name }) {
      return name;
    },
  },
  components: {
    LoadingButton,
    LoadingState,
    PupilsLinks,
    'loading-button': LoadingButton,
    modal: ModalJS,
    Multiselect,
    VueGoodTable,
  },
  watch: {
    'modal.modals.addPupil': function () {
      this.selectedPupils = [];
    },
  },
  computed: {
    groupPupils() {
      return this._.filter(this.pupils, (pupil) => !pupil.is_available);
    },
  },
};
</script>
<style scoped lang="scss">
  .table-responsive {
    border-width: 0;
  }

  .class-info {
    /*margin:10px 0 20px 0;*/
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    .class-name {
      display: flex;
      .name {
        min-width: 100px;
        &.form-control {
          border-top: none;
          border-left: none;
          border-right: none;
        }
      }
    }
  }

  .class-info .fa {
    font-size: 14px;
    cursor: pointer;
  }

  .class-info input {
    margin-right: 10px;
  }

  .class-info h2 {
    margin:0;
  }

  #pupils h3 a {
    cursor: pointer;
  }

  .box-tools button {
    margin-left: 5px;
  }

  .button-locator {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 85%;
    margin-left: 7.5%;
  }
  .assigned-teacher {
    font-size: 16px;
    width: 20%;
    display: flex;
    justify-content: flex-end;
  }
  .assigned-teacher select {
    min-width: 70%;
  }
  .class-info .assigned-teacher__actions {
    margin-left: 10px;
  }
  .class-info .assigned-teacher__actions .fa {
    margin-left: 10px;
  }
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
