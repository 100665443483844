<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <form ref="observer" data-testid="login-form" class="ui form" @submit.prevent="handleSubmit(validateForm)">
      <ValidationProvider rules="required|email" name="Email" v-slot="{ errors }" ref="emailProvider">
        <div class="form-group" :class="{ 'has-error': errors[0] }">
          <div class="input-group">
            <span class="input-group-addon"><i class="icon icon-envelope"></i></span>
            <input class="form-control" type="email" name="email" placeholder="Email" :value="value.email"
                   @input="update('email', $event.target.value)">
          </div>
          <span v-show="errors" class="text-danger">{{ errors[0] }}</span>
        </div>
      </ValidationProvider>

      <ValidationProvider rules="required" name="Password" v-slot="{ errors }" ref="passwordProvider">
        <div class="form-group" :class="{ 'has-error': errors[0] }">
          <div class="input-group">
            <span class="input-group-addon"><i class="icon icon-lock"></i></span>
            <input class="form-control" type="password" name="password" placeholder="Password" :value="value.password"
                   @input="update('password', $event.target.value)" id="password-login">
          </div>
          <span v-show="errors" class="text-danger">{{ errors[0] }}</span>
        </div>
      </ValidationProvider>

      <loading-button data-testid="login-button" :is-loading="loading" class="btn-block">Login</loading-button>
    </form>
  </ValidationObserver>
</template>

<script>
import LoadingButton from '../utils/LoadingButton';
import FormMixin from '../mixins/FormMixin';

export default {
  name: 'login-form',
  props: {
    value: {
      type: Object,
      required: true,
    },
    isLoading: {
      required: false,
    },
  },
  mixins: [FormMixin],
  components: { LoadingButton },
  computed: {
    loading() {
      return this.isLoading;
    },
  },
};
</script>
