<template>
    <div class="box" :class="boxClass">
        <div class="box-header">
            <h3 class="box-title">{{ title }}</h3>
        </div>
        <div class="box-body no-padding table-responsive">
            <table class="table table-borderless">
                <tbody>
                <tr>
                    <th class="cell-25">Contact Name</th>
                    <td>{{ value.name }}</td>
                </tr>
                <tr>
                    <th class="cell-25">Job Role</th>
                    <td>{{ value.job_role }}</td>
                </tr>
                <tr>
                    <th>Address Line 1</th>
                    <td>{{ value.address.line1 }}</td>
                </tr>
                <tr>
                    <th>Address Line 2</th>
                    <td>{{ value.address.line2 }}</td>
                </tr>
                <tr>
                    <th>Postcode</th>
                    <td>{{ value.postcode }}</td>
                </tr>
                <tr>
                    <th>Country</th>
                    <td>{{ value.country }}</td>
                </tr>
                <tr>
                    <th>Region</th>
                    <td>{{ value.region }}</td>
                </tr>
                <tr>
                    <th>City</th>
                    <td>{{ value.city }}</td>
                </tr>
                <tr>
                    <th>County</th>
                    <td>{{ value.county }}</td>
                </tr>
                <tr>
                    <th>Phone</th>
                    <td>{{ value.phone }}</td>
                </tr>
                <tr>
                    <th>Email</th>
                    <td>{{ value.email }}</td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
export default {
  name: 'ContactDetails',
  props: {
    value: {},
    title: String,
    boxClass: String,
  },
};
</script>
