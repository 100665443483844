<template>
  <section class="content">

    <div class="d-flex align-items-center justify-content-end mb-5">
      <div>
        <date-picker-with-dropdown
          reference="time_period"
          @date-range-change="dateRangeUpdated"
          v-model="dateRange"
          :initialDateRange="dateRange"
        />
      </div>
    </div>

    <div class="d-flex justify-content-between align-items-center mb-5">

      <div class="d-flex flex-column">

        <div class="d-flex mb-5">
          <div class="stat-box me-5">
            <span class="title">Total Reading Time <i class="fa fa-question-circle text-primary" @click="modal.show('reading-time-tip')" /></span>
            <span class="value text-center" v-html="formatSecondsAsDuration(total_reading_seconds, '<br />', 2)"></span>
          </div>
          <div class="stat-box me-5">
            <span class="title">Average time per book</span>
            <span class="value text-center" v-html="formatSecondsAsDuration(average_reading_seconds, '<br />', 2)"></span>
          </div>
        </div>

        <div class="d-flex">
          <div class="stat-box me-5">
            <span class="title">Books completed</span>
            <span class="value">{{books_completed}}</span>
          </div>
          <div class="stat-box me-5">
            <span class="title">Books in-progress</span>
            <span class="value">{{books_in_progress}}</span>
          </div>
          <div class="stat-box me-5">
            <span class="title">Books not started <i class="fa fa-question-circle text-primary" v-tooltip="'Number of assigned books they have not yet started to read'"></i></span>
            <span class="value">{{books_not_started}}</span>
          </div>
        </div>

      </div>

      <div class="d-flex flex-column">
        <button class="btn mb-3" v-if="userCanUpdate" @click="editableReaderName = readerName; modal.show('update-pupil-name')">UPDATE PUPIL NAME</button>
        <button class="btn btn-danger mb-3" v-if="userCanUpdate" @click="modal.show('reset-pupil-password')">RESET PASSWORD</button>
        <button class="btn btn-primary" @click="$router.push(`/pupils/${readerId}/books`)">MANAGE BOOKS</button>
      </div>

    </div>

    <div class="">
      <span class="pull-right text-italic text-xs text-bold mb-3"><i class="fa fa-info-circle"></i> Only the latest read per book is displayed. Click more info to see all reads.</span>
       <vue-good-table
        styleClass="vgt-table striped dataTable"
        theme="auris-admin"
        :columns="books_table_columns"
        :rows="books_table_data"
        :search-options="{
          enabled: false,
        }"
        :pagination-options="{
          enabled: true,
          mode: 'pages',
          perPage: parseInt(rowsPerPage),
          position: 'bottom',
          perPageDropdown: rowsPerPageOptions,
          dropdownAllowAll: false,
          setCurrentPage: 1,
          nextLabel: 'next',
          prevLabel: 'prev',
          rowsPerPageLabel: 'Rows per page',
          ofLabel: 'of',
          pageLabel: 'page', // for 'pages' mode
          allLabel: 'All',
        }"
        :sort-options="{
          enabled: true,
          initialSortBy: { field: 'date', 'type': 'desc'}
        }"
        >
        <div slot="emptystate" class="text-center">No reading sessions found for this pupil in the given date range.</div>
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field === 'accuracy'">
            <template v-if="props.row.has_completed_book">{{ props.formattedRow['accuracy'] }}</template>
            <template v-else>{{ props.formattedRow['range_progressive_accuracy'] }}</template>
          </span>
          <span v-else-if="props.column.field == 'actions'">
            <router-link :to="{ path: `/pupils/${readerId}/books/${props.row.book_id}`, query: { rid: props.row.id } }" class="btn btn-more-info"><i class="icon icon-arrow-circle-right"></i> More Info</router-link>
          </span>
          <a class="u-hover__pointer" v-else-if="props.column.field == 'book_title'" @click="$router.push({path: `/pupils/${readerId}/books/${props.row.book_id}`, query: { rid: props.row.id}});">
            {{props.formattedRow[props.column.field]}}
          </a>
          <span v-else>
            {{props.formattedRow[props.column.field]}}
          </span>
        </template>
      </vue-good-table>
    </div>

    <!-- Modal: Reading Time Tip -->
    <modal title="Reading Time" v-if="modal.isVisible('reading-time-tip')" @close="modal.hide('reading-time-tip')" :showFooter="false">
      <p>This is the total time spent reading between {{ currentDateRangeStart }} and {{ currentDateRangeEnd }}.</p>
      <p>This number may differ to the reading times in the table below it, as the table displays the reading time of the latest read-through of each book.</p>
      <p>Multiple read-throughs within the selected date period or partial reading outside of the selected date period will affect how much this differs.</p>
      <template slot="footer">
        <button type="button" class="btn btn-lg btn-primary" :disabled="!isValidNewPupilName" @click="modal.hide('update-pupil-name'); saveUpdatedPupilName()">Save Pupil Name</button>
      </template>
    </modal>
    <!--/ Modal: Update Pupil Name -->

    <!-- Modal: Update Pupil Name -->
    <modal title="Update pupil name" v-if="modal.isVisible('update-pupil-name')" @close="modal.hide('update-pupil-name')" :showFooter="true">
      <input type="text" class="input-pupil-name" :class="[isValidNewPupilName ? 'is-valid' : 'is-invalid']" v-model="editableReaderName" />
      <template slot="footer">
        <button type="button" class="btn btn-lg btn-primary" :disabled="!isValidNewPupilName" @click="modal.hide('update-pupil-name'); saveUpdatedPupilName()">Save Pupil Name</button>
      </template>
    </modal>
    <!--/ Modal: Update Pupil Name -->

     <!-- Modal: Reset Pupil Password -->
    <modal title="Reset Pupil Password" v-if="modal.isVisible('reset-pupil-password')" @close="modal.hide('reset-pupil-password')" :showFooter="true">
      <template v-if="passwordResetStatus === 'loading'">
        <p class="text-center"><i class="fa fa-spinner fa-spin"></i><br />Please wait&hellip;</p>
      </template>
      <template v-else-if="passwordResetStatus === 'complete'">
        <p>{{readerName}}'s password is being reset.</p>
        <p>You will receive their new login information <strong>via email</strong> shortly.</p>
      </template>
      <template v-else>
        <p>The pupils existing password and QR code will be removed, and new one's generated to replace them.</p>
        <p>Confirm you wish to continue by clicking the "Reset Password" button below.</p>
      </template>
      <template slot="footer">
        <button type="button" class="btn btn-danger" @click="resetPupilPassword" v-show="passwordResetStatus !== 'complete'" :disabled="passwordResetStatus !== null">Reset Password</button>
      </template>
    </modal>
    <!--/ Modal: Reset Pupil Password -->
  </section>
</template>
<script>
// import _ from 'lodash';
import DatePickerWithDropdown from '@/components/inputs/DatePickerWithDropdown';
import CurrentUserMixin from '@/components/mixins/CurrentUserMixins';
import moment from 'moment';
import { VueGoodTable } from 'vue-good-table';
import TableMixin from '@/components/mixins/TableMixin';
import formatString from '@/utils/format-string';
import ModalJS from '@/components/utils/Modal';
import Modal from '@/components/classes/Modal';
import { deburr } from 'lodash';

export default {
  name: 'pupil',
  mixins: [CurrentUserMixin, DatePickerWithDropdown, TableMixin],
  components: {
    DatePickerWithDropdown,
    VueGoodTable,
    modal: ModalJS,
  },
  data() {
    return {
      readerId: null,
      readerName: '',
      editableReaderName: '', // Used in the modal
      userCanUpdate: false,
      passwordResetStatus: null,

      isLoading: false,
      dateRange: {
        start: this.$store.state.defaultDateRange ? moment(this.$store.state.defaultDateRange.startDate).toDate() : moment().startOf('month').startOf('day').toDate(),
        end: this.$store.state.defaultDateRange ? moment(this.$store.state.defaultDateRange.endDate).toDate() : moment().endOf('day').toDate(),
      },

      modal: new Modal({
        updatePupilName: false,
        resetPupilPassword: false,
        links: false,
        readingTimeTip: false,
      }),

      total_reading_seconds: 0,
      average_reading_seconds: 0,
      books_completed: 0,
      books_in_progress: 0,
      books_not_started: 0,

      books_table_data: [],

      books_table_columns: [
        {
          label: 'Book',
          field: 'book_title',
          sortable: true,
        },
        {
          label: 'Date',
          field: 'date',
          sortable: true,
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ssXXX',
          dateOutputFormat: 'do LLLL yyyy',
        },
        {
          label: 'Time Reading',
          field: 'reading_time',
          sortable: true,
          formatFn: this.formatTimeReading,
          sortFn: this.intSort,
        },
        {
          label: 'Accuracy',
          field: 'accuracy',
          sortable: true,
          formatFn: this.formatPercentage,
          sortFn: this.accuracySort,
        },
        {
          label: 'Range Progressive Accuracy',
          field: 'range_progressive_accuracy',
          hidden: true,
          formatFn: this.formatPercentage,
        },
        {
          label: 'Completed',
          field: 'has_completed_book',
          sortable: false,
          formatFn: this.formatYesNo,
        },
        {
          label: 'Actions',
          field: 'actions',
          sortable: false,
          tdClass: 'actions',
        },
      ],
    };
  },
  created() {
    this.$store.dispatch('clearCurrentPupil');
  },
  beforeMount() {
    this.readerId = this.$route.params.id;
    this.$http.get(`enterprises/${this.getUserSchool().id}/readers/${this.readerId}`)
      .then((response) => {
        this.readerName = response.data.reader.name;
        this.editableReaderName = response.data.reader.name;
        this.userCanUpdate = response.data.reader.can_update;

        this.$store.dispatch('setCurrentPupil', { name: this.readerName, id: this.readerId });
        this.setPageTitle(`${this.readerName}'s`, 'Reading history');
      })
      .catch((error) => {
        this.$apiResponse.renderErrorMessage(error);
      });
    this.loadPupilData();
    // this.links = {};
    // this.modal.show('links');
  },
  methods: {
    formatSecondsAsDuration: formatString.formatSecondsAsDuration,
    formatTimeReading(seconds) {
      return formatString.formatSecondsAsDuration(seconds);
    },
    intSort(col1, col2) {
      const val1 = parseFloat(col1);
      const val2 = parseFloat(col2);

      return (val1 < val2 ? -1 : (val1 > val2 ? 1 : 0));
    },
    accuracySort(a, b, something, col1, col2) {
      let accuracy1 = a;
      let accuracy2 = b;
      if (!col1.has_completed_book) accuracy1 = col1.range_progressive_accuracy;
      if (!col2.has_completed_book) accuracy2 = col2.range_progressive_accuracy;

      accuracy1 = parseFloat(accuracy1);
      accuracy2 = parseFloat(accuracy2);

      return (accuracy1 < accuracy2 ? -1 : (accuracy1 > accuracy2 ? 1 : 0));
    },
    formatPercentage(value) {
      if (value < 1) { return '< 1%'; }
      if (value >= 100) return '100%';
      return `${Math.floor(value)}%`;
    },
    formatYesNo(value) {
      return value ? 'Yes' : 'No';
    },
    saveUpdatedPupilName() {
      this.editableReaderName = this.editableReaderName.trim();
      if (this.editableReaderName !== this.readerName) {
        this.$http.post(`enterprises/${this.getUserSchool().id}/readers/${this.readerId}`, { name: this.editableReaderName })
          .then(() => {
            this.$store.dispatch('setCurrentPupil', { name: this.editableReaderName });
            this.readerName = this.editableReaderName;
            this.setPageTitle(`${this.readerName}'s`, 'Reading history');
          })
          .catch((e) => {
            this.$apiResponse.renderErrorMessage(e);
          });
      }
    },
    resetPupilPassword() {
      this.passwordResetStatus = 'loading';
      this.$http.post(`enterprises/${this.getUserSchool().id}/readers/password/reset`, { reader_ids: [this.readerId] })
        .then(() => {
          this.passwordResetStatus = 'complete';
        })
        .catch((error) => {
          this.$apiResponse.renderErrorMessage(error);
          this.passwordResetStatus = null;
        });
    },
    dateRangeUpdated(dates) {
      // format the dates object so it works with the old system
      const { start, end } = dates;
      const momentDates = { startDate: moment(start), endDate: moment(end) };
      this.$store.commit('SET_DEFAULT_DATE_RANGE', momentDates);
      this.dateRange = { start: momentDates.startDate.toDate(), end: momentDates.endDate.toDate() };
      this.loadPupilData();
    },
    loadPupilData() {
      this.$http.get(`enterprises/${this.getUserSchool().id}/readers/${this.readerId}/summary`, { params: { start_time: this.dateRange.start, end_time: this.dateRange.end } })
        .then((response) => {
          const json = response.data;
          this.total_reading_seconds = json.total_reading_time;
          this.average_reading_seconds = json.average_reading_time;
          this.books_completed = json.books_completed;
          this.books_in_progress = json.books_in_progress;
          this.books_not_started = json.books_not_started;
          this.books_table_data = json.reading_sessions;
        })
        .catch((error) => {
          this.$apiResponse.renderErrorMessage(error, 'error');
        });
    },
  },
  computed: {
    isValidNewPupilName() {
      // Using deburr to swap accented chars for english ones, so we can validate A-Z
      // Javascript doesn't appear to have a built-in regex pattern for "letters of any language";
      return deburr(this.editableReaderName).match(/^[a-zA-Z\- ]{2,191}$/);
    },
    currentDateRangeStart() {
      return moment(this.dateRange.start).format('D MMMM YYYY');
    },
    currentDateRangeEnd() {
      return moment(this.dateRange.end).format('D MMMM YYYY');
    },
  },
};
</script>

<style lang="scss" scoped>

  .stat-box {
    display: flex;
    flex-direction: column;
    background: white;
    //box-shadow: 0 2px 2px rgba(0,0,0,0.2);
    padding: 10px 15px;
    align-items: center;
    border: 2px solid #1e8ea7;
    line-height:1;
    .title {
      margin-bottom:0.5em;
    }
    .value {
      font-size: 2em;
      font-weight: 500;
    }
  }

  input.input-pupil-name {
    text-align: center;
    font-size: 3rem;
    border-width: 0;
    border-bottom-width: 2px;
    width: 100%;
    border-color: #ccc;
    outline:none;
    &:xfocus {
      border-color: green;
    }
    &.is-invalid {
      border-color: red;
    }
  }

</style>
