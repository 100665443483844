export default {
  TOGGLE_LOADING(state) {
    state.callingAPI = !state.callingAPI;
  },
  TOGGLE_SEARCHING(state) {
    state.searching = (state.searching === '') ? 'loading' : '';
  },
  SET_USER(state, user) {
    state.user = user;
  },
  SET_TOKEN(state, token) {
    state.token = token;
  },
  SET_EMAIL_ADDRESS(state, email) {
    state.emailAddress = email;
  },
  SET_ORIGIN(state, origin) {
    state.origin = origin;
  },
  ACCEPT_TERMS_AND_CONDITIONS(state, value) {
    state.termsAndConditionsAccepted = value;
  },
  HAS_SUBSCRIPTION(state, value) {
    state.hasSubscription = value;
  },
  HAS_PASSED_SUBSCRIPTION_SCREENING(state, value) {
    state.hasPassedSubscriptionScreening = value;
  },
  SET_CURRENT_GROUP(state, value) {
    state.currentGroup = value;
  },
  SET_CURRENT_SCHOOL(state, value) {
    state.currentSchool = value;
  },
  SET_CURRENT_CHARTS_GROUP(state, value) {
    state.currentChartsGroup = value;
  },
  SET_DEFAULT_DATE_RANGE(state, dates) {
    if (window.localStorage) {
      window.localStorage.setItem('default_date_range', JSON.stringify(dates));
    }
    state.defaultDateRange = dates;
  },
  SET_ROWS_PER_PAGE(state, n) {
    if (window.localStorage) {
      window.localStorage.setItem('number_of_rows', n);
    }
    state.rowsPerPage = n;
  },
  SET_CURRENT_PUPIL(state, pupil) {
    state.currentPupil = pupil;
  },
  SET_CURRENT_BOOK(state, book) {
    state.currentBook = book;
  },
  FILL_COUNTRIES(state, countries) {
    state.countries = countries;
  },
};
