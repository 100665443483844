import _ from 'lodash';

const isSchoolAdmin = function (store) {
  return store.state.user.enterprise && store.state.user.enterprise && store.state.user.enterprise.user_manages;
};

const isNotInSchool = function (store) {
  return store.state.user.enterprise === undefined || _.isEmpty(store.state.user.enterprise);
};

const isAssignedTeacher = function (store, params) {
  const { group } = params;
  return group.user_manages;
};

const isAnyUser = function () {
  return true;
};

const checks = {
  isSchoolAdmin,
  isNotInSchool,
  isAssignedTeacher,
  isAnyUser,
};

export default checks;
