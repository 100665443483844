export default {
  data() {
    return {};
  },
  methods: {
    isInSchool() {
      return this.$store.state.user.enterprise !== undefined && !this._.isEmpty(this.$store.state.user.enterprise);
    },
    getSubscriptions(schoolId) {
      return new Promise((resolve, reject) => {
        this.$http.get(`enterprises/${schoolId}/subscriptions`)
          .then((response) => {
            resolve(response.data.subscriptions);
          })
          .catch((error) => {
            this.$apiResponse.renderErrorMessage(error);
            reject(error);
          });
      });
    },
    handleSubscriptionProcess() {
      return new Promise((resolve, reject) => {
        this.$store.commit('HAS_PASSED_SUBSCRIPTION_SCREENING', true);
        const school = this.$store.state.user.enterprise;
        if (this.$store.state.user.user_type !== 'enterprise') resolve('/subscription/warning');
        if (this._.isEmpty(school)) resolve('/subscription/register');

        this.$http.get(`enterprises/${school.id}`)
          .then((response) => {
            const { enterprise } = response.data;
            const { subscription } = enterprise;
            if (this._.isEmpty(subscription)) { // no subscription at all, so we ask them to create one
              resolve('/subscription/start');
            }

            let isActive = false;
            // eslint-disable-next-line consistent-return
            if (!subscription.has_expired) {
              isActive = true;
              this.$store.commit('HAS_SUBSCRIPTION', true);
            }

            resolve(isActive ? '/' : '/subscription/renew');
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
