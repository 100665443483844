<template>
  <div class="container container-table">
    <div class="row">
      <div class="col-md-3 col-sm-1"></div>
      <div class="col-md-6 col-sm-10">
        <div class="row">
          <div class="col-centered col-md-10">
            <div class="register-form box-front">
              <img src="../assets/img/fonetti-logo-new.png" class="center-block logo">
              <action-messages></action-messages>
              <register-form v-model="form" :is-loading="isLoading" @validated="register"></register-form>
              <div class="extra-links">
                <router-link to="/login">Login to your Account</router-link>
                <span>
                  <router-link to="/forgotten">Forgotten Password</router-link>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-sm-1"></div>
    </div>
  </div>
</template>

<script>
import SubscriptionMixin from '@/components/mixins/SubscriptionMixin';
import InviteMixin from '@/components/mixins/InviteMixin';
import Form from '@/components/classes/Form';
import RegisterForm from '@/components/forms/RegisterForm';

export default {
  name: 'Register',
  mixins: [SubscriptionMixin, InviteMixin],
  data() {
    return {
      section: 'Register',
      loading: '',
      form: new Form({
        first_name: '',
        last_name: '',
        email: this.$store.state.emailAddress,
        password: '',
        job_role: '',
        user_type: 'enterprise',
      }),
      invite: null,
      currentStep: 'step1',
      isLoading: false,
    };
  },
  mounted() {
    this.invite = window.localStorage.getItem('invite');
    this.form.email = window.localStorage.getItem('invitee_email') || this.form.email;
    window.localStorage.removeItem('invitee_email');
    this.$apiResponse.showHeading(false);
  },
  methods: {
    register() {
      this.isLoading = true;

      /* Making API call to authenticate a user */
      this.$http.post('users', this.form)
        .then((response) => {
          const { data } = response;

          /* Setting user in the state and caching record to the localStorage */
          if (data.user) {
            const { token } = data.meta;

            this.$store.dispatch('setUser', data.user);
            this.$store.dispatch('setToken', token);
            this.$store.dispatch('setEmail', this.form.email);
            this.$store.dispatch('setOrigin', 'registration');

            if (this.invite) {
              return this.handleInviteProcess(this.invite);
            }
            this.$store.commit('HAS_PASSED_SUBSCRIPTION_SCREENING', true);
            return this.$router.push('/subscription/register');
          }
          return this.$router.push('/logout');
        })
        .then((path) => {
          this.isLoading = false;
          this.$router.push(path);
        })
        .catch((error) => {
          this.$apiResponse.renderErrorMessage(error);
          this.isLoading = false;
        });
    },
  },
  components: {
    RegisterForm,
  },
};
</script>
<style>
.container-table {
    display: table;
    color: white;
}
</style>

<style scoped>
  .action-messages {
    margin-bottom: 15px;
  }
</style>
