<template>
  <!-- Main content -->
  <section class="content">
    <div class="row">
      <div class="col-md-8 col-lg-6">
        <div class="box">
          <div class="box-header">
            <h3 class="box-title">Subscription Information</h3>
          </div>
          <div class="box-body no-padding table-responsive" v-if="school.subscription">
            <table class="table table-borderless">
              <tbody>
              <tr>
                <th class="cell-25">Renewal Status</th>
                <td>{{ school.subscription.renewal_status }}</td>
              </tr>
              <tr>
                <th>Expiry Date</th>
                <td>{{ school.subscription.expires_at }}</td>
              </tr>
              <tr>
                <th>Max Pupils</th>
                <td>{{ school.subscription.max_readers }}</td>
              </tr>
              <tr>
                <th>Duration</th>
                <td>{{ school.subscription.renewal_duration_length }} {{ pluralize(school.subscription.renewal_duration_length, school.subscription.renewal_duration_unit) }}</td>
              </tr>
              <tr>
                <th>Price</th>
                <td>{{ formatCurrency(school.subscription.payment_amount) }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="col-md-4 col-lg-6 text-right">
        <p><a href="https://www.fonetti.com/privacy-policy/" target="_blank" rel="noopener noreferrer" alt="opens in new window" class="btn btn-primary btn-fix-34">Privacy Policy</a></p>
        <p><a href="https://www.fonetti.com/terms-of-use/" target="_blank" rel="noopener noreferrer" alt="opens in new window" class="btn btn-primary btn-fix-34">Terms &amp; Conditions</a></p>
        <p><a href="https://www.fonetti.com/wp-content/uploads/2021/02/School-EULA-v1.pdf" target="_blank" rel="noopener noreferrer" alt="opens in new window" class="btn btn-primary btn-fix-34">End User License Agreement</a></p>
      </div>
      <div class="col-md-12">
        To discuss your subscription, please <a href="https://www.fonetti.com/get-in-touch/" target="_blank">drop us a message via our contact form</a>.
      </div>
    </div>
  </section>
  <!-- /.content -->
</template>

<script>
import SubscriptionMixin from '@/components/mixins/SubscriptionMixin';
import CurrentUserMixin from '@/components/mixins/CurrentUserMixins';
import StringUtils from '@/components/mixins/StringUtils';

export default {
  mixins: [SubscriptionMixin, CurrentUserMixin, StringUtils],
  data() {
    return {
      school: null,
      isLoading: false,
    };
  },
  mounted() {
    if (!this.$authorization.isAuthorized('manage_subscription')) {
      this.$router.push('/');
    }
    this.school = this.getUserSchool();
  },
  methods: {
    formatCurrency(number) {
      return Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(number);
    },
  },
};
</script>
<style scoped>
.contact form {
  margin-top: 40px;
}
</style>
