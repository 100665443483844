<template>
  <section class="container container-table">
    <div class="row">
      <div class="col-centered col-md-6 col-sm-10">
        <div class="row">
          <div class="col-centered col-md-10">

            <div class="row">
              <div class="box box-front">
                <img src="../assets/img/fonetti-logo-new.png" class="center-block logo__reset-password">
                <action-messages></action-messages>
                <template v-if="!resetSuccessful && formEnabled">
                  <div class="box-header with-border">
                    <h3 class="box-title text-center">Enter a new password</h3>
                  </div>
                  <div class="box-body">
                    <reset-password-form v-if="formEnabled" v-model="form" @validated="resetPassword"></reset-password-form>
                  </div>
                </template>
                <template v-if="resetSuccessful">
                  <div class="alert alert-success">
                    <p>Great, your password has been reset successfully. You can now login using your new password and the email address you registered with us.</p>
                  </div>
                  <button class="btn btn-primary" @click="toNextUrl()">Go to login</button>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import _ from 'lodash';
import ResetPasswordForm from '@/components/forms/ResetPasswordForm';
import ActionMessages from '../plugins/APIResponse/components/ActionMessages';

export default {
  name: 'reset-password',
  components: {
    ResetPasswordForm,
    ActionMessages,
  },
  data() {
    return {
      form: {
        password: '',
      },
      formEnabled: false,
      resetSuccessful: false,
    };
  },
  mounted() {
    // this.$store.commit('SET_LAYOUT', 'guest-layout');
    const { token } = this.$route.query;
    if (_.isEmpty(token)) {
      this.$router.replace('/');
    }
    this.token = token;
    this.validateToken(token);
  },
  methods: {
    validateToken(token) {
      this.$http.post('users/password/verify', { token })
        .then(() => {
          this.formEnabled = true;
        })
        .catch((error) => {
          this.$apiResponse.renderErrorMessage(error, 'Sorry, the token has expired. Click <a href="/password/forgotten">here</a> to try again.', 'error');
          this.formEnabled = false;
        });
    },
    resetPassword() {
      // this.buttonGroupIsLoading('password', true);
      this.$http.post('users/password/update', { token: this.token, password: this.form.password })
        .then(() => {
          this.formEnabled = false;
          this.resetSuccessful = true;
        })
        .catch((error) => {
          this.$apiResponse.renderErrorMessage(error, 'error');
        });
      // .finally(() => this.buttonGroupIsLoading('password', false));
    },
    toNextUrl() {
      this.$router.push('/');
    },
  },
};
</script>
