<template>
  <div class="book-details">
    <div class="cover">
      <figure>
        <img :src="fixCoverImageUrl(book.cover_image, 200, 'width')" alt="">
        <figcaption>Reading Level {{ book.reading_level }}</figcaption>
      </figure>
    </div>
    <div>
      <div class="title">{{ book.title }}</div>
      <div class="summary">{{ book.description }}</div>
      <ul class="tags">
        <li v-for="tag in book.tags" :key="tag.id">{{ tag.text }}</li>
      </ul>
    </div>
  </div>
</template>

<script>
import BooksMixin from '../mixins/BooksMixin';

export default {
  name: 'book-details',
  props: ['book'],
  mixins: [BooksMixin],
};
</script>

<style scoped>
  .book-details {
    display: flex;
  }
  .cover {
    text-align: center;
    padding-right: 10px;
  }
  figcaption {
    text-align: center;
    font-weight: 600;
    font-size: 14px;
  }
  .title {
    font-weight: 600;
    font-size: 1.2em;
    margin-bottom: 15px;
  }
  .summary {
    margin-bottom: 25px;
  }
  .tags {
    padding-left: 0
  }
  .tags li {
    display: inline-block;
    background-color: #1f95b0;
    color: #fff;
    padding: 3px 10px;
    margin: 2px;
    font-size: 0.9em;
  }
  @media (max-width: 498px) {
    .book-details {
      flex-direction: column;
    }
    .title {
      margin-top: 10px;
      border-top: 1px solid #333;
      padding-top: 5px;
    }
  }
</style>
