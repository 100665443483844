<template>
  <ValidationObserver v-slot="{ passes }">
    <form ref="observer" class="ui form" @submit.prevent="passes(validateForm)">
      <ValidationProvider :rules="{ required: true, min: 2, }" name="First Name" v-slot="{ errors }">
        <div class="form-group" :class="{ 'has-error': errors[0] }">
          <input class="form-control" id="first_name" name="first_name" placeholder="First name" type="text" v-model="mutableValue.first_name">
          <span v-show="errors" class="text-danger">{{ errors[0] }}</span>
        </div>
      </ValidationProvider>

      <ValidationProvider :rules="{ required: true, min: 2 }" name="Last Name" v-slot="{ errors }">
        <div class="form-group" :class="{ 'has-error': errors[0] }">
          <input class="form-control" id="last_name" name="last_name" placeholder="Last name" type="text" v-model="mutableValue.last_name">
          <span v-show="errors" class="text-danger">{{ errors[0] }}</span>
        </div>
      </ValidationProvider>

      <ValidationProvider rules="required|email" name="Email" v-slot="{ errors }">
        <div class="form-group" :class="{ 'has-error': errors[0] }">
          <input class="form-control" id="email" name="email" placeholder="Email" type="email" v-model="mutableValue.email">
          <span v-show="errors" class="text-danger">{{ errors[0] }}</span>
        </div>
      </ValidationProvider>

      <ValidationProvider name="Job Role" v-slot="{ errors }">
        <div class="form-group" :class="{ 'has-error': errors[0] }">
          <input class="form-control" id="job_role" name="email" placeholder="Job Role" type="text" v-model="mutableValue.job_role">
          <span v-show="errors" class="text-danger">{{ errors[0] }}</span>
        </div>
      </ValidationProvider>

      <ValidationProvider :rules="{ required: true, min: 7 }" name="Password" v-slot="{ errors }">
        <div class="form-group" :class="{ 'has-error': errors[0] }">
          <input class="form-control" id="password" name="password" placeholder="Password" type="password" v-model="mutableValue.password">
          <span v-show="errors" class="text-danger">{{ errors[0] }}</span>
        </div>
      </ValidationProvider>

      <loading-button :is-loading="loading" class="btn-block">Register</loading-button>
    </form>
  </ValidationObserver>
</template>

<script>
import LoadingButton from '../utils/LoadingButton';
import FormMixin from '../mixins/FormMixin';

export default {
  name: 'register-form',
  props: ['value', 'isLoading'],
  mixins: [FormMixin],
  components: { LoadingButton },
  computed: {
    loading() {
      return this.isLoading;
    },
    mutableValue() {
      return Object.assign(this.value);
    },
  },
};
</script>
